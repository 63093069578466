import React from 'react';
import * as AV from '../AVCore/AVCore';
import { useQuery } from 'react-query';
import { setUser, setFeedsLoad } from '../Redux/Actions/userActions';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setFeedOwners, setFeeds, setFetchProcess, setPostList, setRemainingPosts, setProcessedEntityIds, setRemainingEntities } from '../Redux/Actions/feedsAction';
import { Store } from '../Platform/Store';
import _ from 'lodash';
import { Alert } from '../AVCore/Alert';


function useGetFeeds ( page, id, isOwner, isFollowing, userInfo )
{
    const [ isRefreshing, setIsRefreshing ] = React.useState( false );
    const dispatch = useDispatch();
    let totalPostCount;
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    let remainingPosts : any = useSelector( ( state : RootStateOrAny ) => state.feeds.remainingPosts );
    let processedEntityIds : any = useSelector( ( state : RootStateOrAny ) => state.feeds.processedEntityIds );
    const feedOwnersList = useSelector( ( state : RootStateOrAny ) =>
        state.feeds.feedowners ? state.feeds.feedowners[ 0 ] : []
    );
    const userfeeds = useSelector( ( state : RootStateOrAny ) => state.feeds.feeds[ 0 ] );
    let feedsval = userfeeds;
    let lastUpdated = new Date( feedsval?.length > 0 ? feedsval[ 0 ]?.lastUpdated : 0 );
    const getFeeds = async () =>
    {
        dispatch( setFeedsLoad( true ) );
        //let feedsval = userfeeds;
        //let lastUpdated = new Date( feedsval?.length > 0 ? feedsval[ 0 ]?.lastUpdated : 0 );
        let feeds : any = {};
        let reportedIds = currentUser?.reported;
        let totalPosts : any[] = [];
        let entitiesProcessed = false;

        if ( page == "Profile" && isFollowing === false && isOwner === false )
        {
            feeds = await fetchFeeds( userInfo, new Date( 0 ), isOwner, reportedIds );
            feeds?.Post?.sort( ( a : any, b : any ) =>
            {
                const createDateA = new Date( a.createDate ) as any;
                const createDateB = new Date( b.createDate ) as any;
                return createDateB - createDateA;
            } );
            let posts = list_to_tree( feeds?.Post );
            dispatch( setFeedsLoad( false ) );
            return posts;
        }
        else if ( page == "Profile" && userInfo?.id && ( isOwner === true || isFollowing == true ) )
        {
            let feedsval = userfeeds?.filter( x => x?.owner?.id == userInfo?.id );
            let lastUpdated = new Date( feedsval?.length > 0 ? feedsval[ 0 ]?.lastUpdated : 0 );

            feeds = await fetchFeeds( userInfo, lastUpdated, isOwner, reportedIds );
            setIsRefreshing( false );
            let returnvalue = list_to_tree( feeds?.Post );
            returnvalue = returnvalue?.sort( ( a : any, b : any ) =>
            {
                const createDateA = new Date( a.createDate ) as any;
                const createDateB = new Date( b.createDate ) as any;
                return createDateB - createDateA;
            } );
            FeedsreduxUpdate( returnvalue, userfeeds );
            return returnvalue;
        }
        else
        {
            let feedsval = userfeeds;
            let followers = _.uniqBy( [ ...( currentUser?.follows || [] ), ...( currentUser?.autoFollows || [] ) ], 'id' );
            followers.push( currentUser );
            if ( followers?.length > 0 )
            {
                feedsval = feedsval?.filter( ( item1 : any ) => followers?.some( ( item2 : any ) => item2?.id === item1?.pk ) );
            }
            if (feedsval?.length > 0)
            {
                dispatch( setFeedsLoad( false ) );
                return feedsval;
            }
            if ( currentUser )
            {
                //feeds = await fetchFeeds( AV.AVEntity.getFk( currentUser ), lastUpdated, true, reportedIds );
                feeds = fetchFollowerFeeds( currentUser, new Date(0), feeds );
                setIsRefreshing( false );
                //feeds?.Post?.sort( ( a : any, b : any ) =>
                //{
                //    const createDateA = new Date( a.createDate ) as any;
                //    const createDateB = new Date( b.createDate ) as any;
                //    return createDateB - createDateA;
                //} );
                let returnvalue = list_to_tree( feeds?.Post );
                //dispatch( setFeedsLoad( true ) );
                //dispatch( setFeeds( returnvalue ) );
                //dispatch( setFeedsLoad( false ) );
                return returnvalue;
            }
            else
                return [];
        }
    }
    const FeedsreduxUpdate = async ( returnvalue, userfeeds ) =>
    {
        returnvalue = returnvalue?.filter( ( item1 : { id : any; } ) => !userfeeds?.some( ( item2 : { id : any; } ) => item2?.id === item1?.id ) );
        returnvalue = returnvalue.concat( userfeeds );
        returnvalue?.sort( ( a : any, b : any ) =>
        {
            const createDateA = new Date( a.createDate ) as any;
            const createDateB = new Date( b.createDate ) as any;
            return createDateB - createDateA;
        } );
        dispatch( setFeeds( returnvalue ) );
        dispatch( setFeedsLoad( false ) );
    }
    const fetchFeeds = async ( Entity, lastUpdated, iscurrentUser, reportedIds ) =>
    {
        let FeedOwnersList = feedOwnersList?.length > 0 ? feedOwnersList : []
        let param = { feed: { id: Entity?.id, pk: Entity?.id, type: "Feed" }, startDate: lastUpdated, forward: true, checkArchive: false };
        let response : any = [];
        if ( param?.feed?.id )
            if ( FeedOwnersList == undefined || FeedOwnersList == null || !FeedOwnersList?.find( ( x : any ) => x?.id == param?.feed?.id ) )
            {
                response = await AV.Feed.getFeed( param );
                if ( response?.id )
                {
                    let allFeedOwners = _.uniqBy( [ ...( feedOwnersList || [] ), ...( response?.id ? [ response ] : [] ) ], 'id' );
                    dispatch( setFeedOwners( allFeedOwners ) );
                }
            }
            else
            {
                response = FeedOwnersList?.find( ( x : any ) => x?.id == param?.feed?.id )
            }
        response.followIds = _.uniqBy( response?.followIds, 'id' );
        response.privateIds = _.uniqBy( response?.privateIds, 'id' );
        response.publicIds = _.uniqBy( response.publicIds, 'id' );
        let PostList = await AV.Feed.getPostList( response );
        let combinedFeedsId = _.uniqBy( [ ...( response?.followIds || [] ), ...( response?.privateIds || [] ), ...( response?.publicIds || [] ), ], 'id' )
        let listofPosts = combinedFeedsId.slice( 20 );
        remainingPosts = remainingPosts.concat(listofPosts);
        dispatch( setRemainingPosts( remainingPosts ) );
        if ( PostList?.length > 0 )
        {
            let Post : any = await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList?.slice( 0, 20 ) );
            for ( let i = 0; i < Post?.length; i++ )
            {
                if ( Post[ i ].posts?.length > 0 )
                {
                    var cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                    if ( cmdPost?.length > 0 )
                    {
                        Post[ i ].children = cmdPost;
                    }
                }
            }
            response.followIds = response?.followIds.map( ( post ) => ( { ...post, owner: response?.id } ) )
            response.privateIds = response?.privateIds.map( ( post ) => ( { ...post, owner: response?.id } ) )
            response.publicIds = response?.publicIds.map( ( post ) => ( { ...post, owner: response?.id } ) )
            let finalCount = response?.followIds?.length + response?.privateIds?.length + response?.publicIds?.length;
            totalPostCount = finalCount;
            // Combine posts and remove duplicates
            let allPosts = _.uniqBy( [ ...( response?.followIds || [] ), ...( response?.privateIds || [] ), ...( response?.publicIds || [] ), ], 'id' )
            return { Post, response, allPosts } || [];
        }
        return [];

    };
    const fetchFollowerFeeds = async ( person, lastUpdated, selfFeeds ) =>
    {
        dispatch( setFeedsLoad( true ) );
        dispatch( setFetchProcess( true ) );
        //lastUpdated.setHours( 0, 0, 0, 0 );
        const currentDate = new Date();
        let past7DaysDate = new Date();
        let latestEntities : any[] = [];
        let remainingEntities: any[] = [];
        past7DaysDate.setDate( currentDate.getDate() - 7 );

        // Filter, sort, and get the first 5 events
        const sortedEvents = _.uniqBy(
            [ ...( person.events?.filter( event => new Date( event.end ) > past7DaysDate ) || [] ) ],
            'id'
        ).sort( ( a, b ) =>
        {
            const createDateA = new Date( a.date ) as any;
            const createDateB = new Date( b.date ) as any;
            return createDateB - createDateA;
        } );

        if ( sortedEvents.length > 0 )
        {
            latestEntities.push( sortedEvents.slice( 0, 5 ) );
            remainingEntities.push( sortedEvents.slice( 5 ) );
        }

        const sortedMembers = _.uniqBy( [ ...( person?.members || [] ) ], 'id'
        ).sort( ( a, b ) =>
        {
            const createDateA = new Date( a.date ) as any;
            const createDateB = new Date( b.date ) as any;
            return createDateB - createDateA;
        } );

        if ( sortedMembers.length > 0 )
        {
            latestEntities.push( sortedMembers.slice( 0, 5 ) );
            remainingEntities.push( sortedMembers.slice( 5 ) );
        }

        const sortedOrgs = _.uniqBy( [ ...( person?.orgs || [] ) ], 'id'
        ).sort( ( a, b ) =>
        {
            const createDateA = new Date( a.date ) as any;
            const createDateB = new Date( b.date ) as any;
            return createDateB - createDateA;
        } );

        if ( sortedOrgs.length > 0 )
        {
            latestEntities.push( sortedOrgs.slice( 0, 5 ) );
            remainingEntities.push( sortedOrgs.slice( 5 ) );
        }

        const selfEntity = [currentUser];
        latestEntities.push( selfEntity );


        const sortedFollows = _.uniqBy( [ ...( person?.follows || [] ) ], 'id'
        ).sort( ( a, b ) =>
        {
            const createDateA = new Date( a.date ) as any;
            const createDateB = new Date( b.date ) as any;
            return createDateB - createDateA;
        } );

        if (sortedFollows.length > 0)
        {
            latestEntities.push( sortedFollows.slice( 0, 5 ) );
            remainingEntities.push( sortedFollows.slice( 5 ) );
        }

        const sortedautoFollow = _.uniqBy( [ ...( person?.autoFollows.filter( x=>x.type == "Person" ) || [] ) ], 'id'
        ).sort( ( a, b ) =>
        {
            const createDateA = new Date( a.date ) as any;
            const createDateB = new Date( b.date ) as any;
            return createDateB - createDateA;
        } );

        if ( sortedautoFollow.length > 0 )
        {
            latestEntities.push( sortedautoFollow.slice( 0, 5 ) );
            remainingEntities.push( sortedautoFollow.slice( 5 ) );
        }
        dispatch( setRemainingEntities( remainingEntities ) );
        let combinedEntity : any[] = [];
        combinedEntity = latestEntities;
        let allPosts : any = _.uniqBy( [ ...( selfFeeds?.allPosts || [] ) ], 'id' );
        let allResponses : any = _.uniqBy( [ ...( [ selfFeeds?.response ] || [] ) ], 'id' );
        if ( allPosts.length >= 20 )
        {
            return;
        }
            allPosts = await handleEntityPosts( combinedEntity, allPosts, allResponses, past7DaysDate, remainingEntities, selfFeeds, false );
            if ( allPosts.length > 0 )
            {
                processFollowerFeeds( allResponses, allPosts, selfFeeds );
            }
            if ( allPosts.length == 0 )
            {
                past7DaysDate = new Date( 0 );

                allPosts = await handleEntityPosts( combinedEntity, allPosts, allResponses, past7DaysDate, remainingEntities, selfFeeds, true );
            }
    };

    const handleEntityPosts = async ( combinedEntity, allPosts, allResponses, past7DaysDate, remainingEntities, selfFeeds, loadFromRedux ) =>
    {
        // Iterate through each follower to fetch their feeds
        for ( let i = 0; i < combinedEntity?.length; i++ )
        {
            for ( let j = 0; j < ( combinedEntity[ i ].length > 5 ? 5 : combinedEntity[ i ].length ); j++ )
            {
                let currentEntity = combinedEntity[ i ];

                // Check the entity is already processed or not
                if ( processedEntityIds.includes( currentEntity[ j ].id ) && past7DaysDate !== new Date(0) )
                {
                    // Skip this iteration and continue with the next one
                    continue;
                }

                dispatch( setFetchProcess( true ) );
                const follower = currentEntity[ j ];
                const feedParam = { feed: { id: follower?.id, pk: follower?.id, type: "Feed" }, startDate: lastUpdated, forward: true, checkArchive: false };
                // Create a function to handle the delay
                const delay = ( ms ) => new Promise( resolve => setTimeout( resolve, ms ) );

                const entity = currentEntity[ j ];
                const entityId = entity.id;
                let feed;
                if ( feedParam?.feed?.id )
                {
                    if ( !loadFromRedux )
                    {
                        feed = await AV.Feed.getFeed( feedParam );
                        if ( feed?.id )
                        {
                            processedEntityIds.push( feed );
                            //processedEntityIds.push( entityId );
                            dispatch( setProcessedEntityIds( processedEntityIds ) );
                            feed.followIds = feed?.followIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) )
                            feed.privateIds = feed?.privateIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) )
                            feed.publicIds = feed?.publicIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) )
                            let currentEntityPostIds = _.uniqBy( [ ...( feed?.followIds || [] ), ...( feed?.privateIds || [] ), ...( feed?.publicIds || [] ) ], 'id' );
                            let remainsPosts = currentEntityPostIds.filter( x => new Date( x.date ) < past7DaysDate );
                            remainingPosts = remainingPosts.concat( remainsPosts );
                            dispatch( setRemainingPosts( remainingPosts ) );
                            currentEntityPostIds = currentEntityPostIds.filter( x => new Date( x.date ) > past7DaysDate )
                            // Combine posts and remove duplicates
                            allPosts = _.uniqBy( [ ...( allPosts || [] ), ...( currentEntityPostIds ) ], 'id' );
                            allResponses.push( feed );
                            console.log( "Processed Feeds::::::::::", processedEntityIds );
                        }
                    }
                    else
                    {
                        //feed = processedEntityIds.filter( pe => pe.id == currentEntity[ j ].id );
                        //feed.followIds = feed?.followIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) );
                        //feed.privateIds = feed?.privateIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) );
                        //feed.publicIds = feed?.publicIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) );
                        //let currentEntityPostIds = _.uniqBy( [ ...( feed?.followIds || [] ), ...( feed?.privateIds || [] ), ...( feed?.publicIds || [] ) ], 'id' );
                        //currentEntityPostIds.slice(0, 20);
                        //let remainsPosts = currentEntityPostIds.slice( 20 );
                        //remainingPosts = remainingPosts.concat( remainsPosts );
                        let postsToLoad = remainingPosts.slice( 0, 20 );
                        remainingPosts = remainingPosts.slice( 20 );
                        dispatch( setRemainingPosts( remainingPosts ) );
                        allPosts = _.uniqBy( [ ...( allPosts || [] ), ...( remainingPosts ) ], 'id' );
                    }
                    if ( allPosts.length > 20 )
                    {
                        const getRemainingEntities = ( combinedEntity : any[][], processedEntityIds : any[][] ) =>
                        {
                            // Flatten the processedEntityIds to make comparison easier
                            const processedIds = processedEntityIds.flat().map( entity => entity.id );

                            return combinedEntity.filter( ( entityGroup : any[] ) =>
                                entityGroup.some( ( entity : any ) => !processedIds.includes( entity.id ) )
                            );
                        };
                        remainingEntities.unshift( getRemainingEntities( combinedEntity, processedEntityIds ) );
                        dispatch( setRemainingEntities( remainingEntities ) );
                        // Example usage
                        console.log( "Get RemainingEntities::", getRemainingEntities( combinedEntity, processedEntityIds ) );

                        // If there are remaining posts, store them for the next scroll
                        if ( allPosts.length > 20 - allPosts.length )
                        {
                            console.log( "All Postst::::::::::::", allPosts );
                            console.log( "Slicing post :::::::::::::::::::", allPosts.slice( 20 - allPosts.length ) );
                            let test = allPosts.slice( 20 );
                            remainingPosts = remainingPosts.concat( test );
                            dispatch( setRemainingPosts( remainingPosts ) );
                            console.log( "Remaining Posts::::::::::", remainingPosts );
                        }
                        allPosts = allPosts.slice( 0, 20 - allPosts.length );
                        processFollowerFeeds( allResponses, allPosts, selfFeeds );
                        return;
                    }
                }
                // Add 3 seconds delay
                //await delay( 3000 );
            }
        }
        return allPosts;
    };

    const handleGetPostList = async ( item ) =>
    {
        var retval : string[] = [];
        for ( var i = 0; item && i < item.length; i++ )
        {
            retval.push( item[ i ].id );
            //@ts-ignore
            for ( var g = 0; item[ i ].gallery && g < item[ i ].gallery.length; g++ )
            {
                //@ts-ignore
                retval.push( item[ i ].gallery[ g ] );
            }
        }
        return retval;
    }

    const processFollowerFeeds = async ( response, postList, selfFeeds ) =>
    {
        dispatch( setFeedsLoad( true ) );
        // Sort PostList by date
        //postList?.sort( ( a, b ) =>
        //{
        //    const createDateA = new Date( a.date ) as any;
        //    const createDateB = new Date( b.date ) as any;
        //    return createDateB - createDateA;
        //} );

        // Combine feed owners from previous fetch with the current response
        let allFeedOwners = _.uniqBy( [ ...( feedOwnersList ? feedOwnersList : [] ), ...( response || [] ) ], 'id' );
        allFeedOwners = allFeedOwners.filter( item => item !== undefined );
        // Store All Feeds and Post 
        dispatch( setFeedOwners( allFeedOwners ) );
        dispatch( setPostList( postList ) );

        // Initialize Today's Posts array with unique self feeds
        let latestPost = _.uniqBy( [ ...( selfFeeds?.Post || [] ) ], 'id' );
        // Remove slef post [already fetch]
        //postList = postList?.filter( ( item1 : { id : any; } ) => !selfFeeds?.Post?.some( ( item2 : { id : any; } ) => item2?.id === item1?.id ) );

        //postList = postList?.filter((x : any) => x?.id == selfFeeds?.Post?.id);
        // Limit PostList to the latest 10 posts
        postList = postList?.slice( 0, 20 );

        // Group posts by owner ID
        const postsByOwner = {};
        postList.forEach( post =>
        {
            const ownerId = post.owner;
            if ( !postsByOwner[ ownerId ] )
            {
                postsByOwner[ ownerId ] = [];
            }
            postsByOwner[ ownerId ].push( post );
        } );

        // Iterate through each owner's posts
        for ( const ownerId in postsByOwner )
        {
            if ( postsByOwner.hasOwnProperty( ownerId ) )
            {
                // Find the owner in the response
                const owner = response.find( x => x?.id === ownerId );
                if ( owner )
                {
                    const postIds = postsByOwner[ ownerId ];
                    // Fetch owner's posts asynchronously
                    const ownerPosts = await handleGetPostList( postIds );
                    if ( ownerPosts?.length > 0 )
                    {
                        // Fetch additional posts for each post
                        let fetchedPosts = await fetchAdditionalPosts( owner, ownerPosts );
                        // Combine fetched posts with Today's Posts
                        latestPost = _.uniqBy( [ ...( latestPost || [] ), ...( fetchedPosts || [] ) ], 'id' );
                    }
                }
            }
        }
        // Convert the list of posts into a tree structure and sort by createDate
        let formattedPosts = latestPost;
        //formattedPosts = formattedPosts?.sort( ( a, b ) =>
        //{
        //    const createDateA = new Date( a.createDate ) as any;
        //    const createDateB = new Date( b.createDate ) as any;
        //    return createDateB - createDateA;
        //} );
        // Update user feeds with the formatted posts
        Feedsupdate( userfeeds, formattedPosts );
        dispatch( setFetchProcess( false ) );
        dispatch( setFeedsLoad( false ) );
    }

    // Function to fetch additional posts for each post
    const fetchAdditionalPosts = async ( owner, posts ) =>
    {
        if ( posts?.length > 0 )
        {
            let Post : any = await AV.Feed.getPosts( AV.AVEntity.getFk( owner ), posts );
            for ( let i = 0; i < Post?.length; i++ )
            {
                if ( Post[ i ].posts?.length > 0 )
                {
                    var cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                    if ( cmdPost?.length > 0 )
                    {
                        Post[ i ].children = cmdPost;
                    }
                }
            }
            return Post;
        }
        return [];
    }

    const Feedsupdate = async ( feedsval, feeds ) =>
    {
        let updatedFeeds = [ ...( feedsval || [] ) ];
        feeds.forEach( feed =>
        {
            let existingFeed = feedsval?.find( x => x?.id === feed?.id );
            if ( !existingFeed )
            {
                updatedFeeds.push( feed );
            } else
            {
                existingFeed.impressions = feed.impressions;
                existingFeed.text = feed.text;
            }
        } );
        updatedFeeds = _.uniqBy( updatedFeeds, 'id' );
        let usersfeeds = list_to_tree( updatedFeeds );
        usersfeeds = _.uniqBy( usersfeeds, 'id' );

        // Define the sort priorities
        //const sortOrder = {
        //    'Event': 1,
        //    'Team': 2,
        //    'LeagueTeam': 3
        //};

        //// Sort usersfeeds by owner.type using sortOrder for priority
        //usersfeeds = usersfeeds.sort( ( a, b ) =>
        //{
        //    return sortOrder[ a.owner.type ] - sortOrder[ b.owner.type ];
        //} );

        //usersfeeds?.sort( ( a : any, b : any ) =>
        //{
        //    const createDateA = new Date( a.createDate ) as any;
        //    const createDateB = new Date( b.createDate ) as any;
        //    return createDateB - createDateA;
        //} );
        if ( page !== "Profile" )
        {
            dispatch( setFeeds( usersfeeds ) );
        }
        return usersfeeds;
    };

    const list_to_tree = ( list ) =>
    {
        var map = {}, node : any, roots : any = [], i;

        for ( i = 0; i < list?.length; i += 1 )
        {
            map[ list[ i ]?.id ] = i; // initialize the map
            if ( list[ i ]?.children?.length === 0 || !list[ i ]?.children )
                list[ i ][ "children" ] = []; // initialize the children
            if ( list[ i ]?.galleryPost?.length === 0 || !list[ i ]?.galleryPost )
                list[ i ][ "galleryPost" ] = [];
        }

        for ( i = 0; i < list?.length; i += 1 )
        {
            node = list[ i ];
            if ( node.parentPost !== undefined )
            {
                if ( list[ map[ node.parentPost ] ]?.gallery?.items?.filter( ( x ) => x === node.id )?.length > 0 && node.gallery == undefined )
                {
                    let excitingpost = list[ map[ node.parentPost ] ]?.galleryPost?.filter( x => x?.id === node?.id );
                    if ( excitingpost?.length === 0 || excitingpost === undefined )
                        list[ map[ node.parentPost ] ]?.galleryPost.push( node );
                } else
                {
                    let excitingcomment = list[ map[ node.parentPost ] ]?.children?.filter( x => x?.id === node?.id );
                    if ( excitingcomment?.length === 0 || excitingcomment === undefined )
                        list[ map[ node.parentPost ] ]?.children.push( node );
                }
            } else
            {
                roots.push( node );
            }
        }
        return roots;
    }


    const onRefresh = () =>
    {
        //set isRefreshing to true
        //getFeeds();
    };

    const fetchEntititesFeeds = async ( person, lastUpdated, selfFeeds ) =>
    {
        let allPosts = _.uniqBy( [ ...( selfFeeds?.allPosts || [] ) ], 'id' );
        let posts : any = [];
        const entityList = _.uniqBy( [
            ...( person.events || [] ),
            ...( person.members || [] ),
            ...( person.orgs || [] ),
            ...( person.follows || [] ),
            ...( person.autoFollow?.filter( x => x.type === 'Person' ) || [] ) ], 'id' );

        // Loop through the entity list to fetch posts
        for ( let i = 0; i < entityList?.length && posts.length < 20; i++ )
        {
            const entity = entityList[ i ];
            const entityId = entity.id;

            // Check if there are remaining posts from the previous fetch for this entity
            if ( remainingPosts[ entityId ]?.length )
            {
                const leftoverPosts = remainingPosts[ entityId ];
                posts.push( ...leftoverPosts.slice( 0, 20 - posts.length ) );

                // Update the remainingPosts for this entity
                setRemainingPosts( ( prev ) => ( {
                    ...prev,
                    [ entityId ]: leftoverPosts.slice( 20 - posts.length ),
                } ) );

                if ( posts.length >= 20 ) break;
            } else
            {
                // Fetch new posts if no leftover posts exist
                const feedParam = {
                    feed: { id: entityId, pk: entityId, type: 'Feed' },
                    startDate: lastUpdated,
                    forward: true,
                    checkArchive: false,
                };

                if ( feedParam.feed.id )
                {
                    const feed = await AV.Feed.getFeed( feedParam );
                    if ( feed?.id )
                    {
                        const combinedPosts = [
                            ...( allPosts || [] ),
                            ...( feed.followIds || [] ),
                            ...( feed.publicIds || [] ),
                            ...( feed.privateIds || [] ),
                        ];

                        posts.push( ...combinedPosts.slice( 0, 20 - posts.length ) );

                        // If there are remaining posts, store them for the next scroll
                        if ( combinedPosts.length > 20 - posts.length )
                        {
                            setRemainingPosts( ( prev ) => ( {
                                ...prev,
                                [ entityId ]: combinedPosts.slice( 20 - posts.length ),
                            } ) );
                        }
                    }
                }
            }
        }

        return posts;
    };


    const { isLoading, data, isError, isFetching, refetch } = useQuery( [ 'allFeeds', id ], getFeeds );
    return {
        isLoading,
        data,
        onRefresh,
        isRefreshing,
        isError,
        isFetching,
        refetch,
        fetchEntititesFeeds,
        fetchFollowerFeeds,
        lastUpdated
    };
}
export default useGetFeeds;