import React, { FC } from "react";
import AVPostPopup from "../../components/common/AVPostPopup";

interface Props {
    setShowCreatePost : any;
    Postdata : any;
}

const CreatePostpopup : FC<Props> = ({ setShowCreatePost, Postdata }) => {

    return (
        <AVPostPopup
            Postdata={ Postdata }
            setShowCreatePost={ setShowCreatePost }
            type={ 'Create' }
        />
    );
};

export default CreatePostpopup;
