import React, { useState, useRef, useCallback } from 'react';
import { Box, VStack, HStack, Text, Divider, ScrollView, Pressable, Button, View, Image, Avatar, FlatList, Tooltip, Hidden, Input, CloseIcon } from 'native-base';
import { Loc } from '../../App/Loc';
import { Dimensions } from 'react-native';
import AVCancel from 'components/common/AVCancel';
import AVInputtext from '../../components/AVInputtext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AddIcon, CrossIcon, SearchIcon, Profile } from '../../assets/icons/icon-assets';
import { useNavigate } from 'react-router-dom';
import ProfilePic from '../../components/common/ProfilePicture';
import * as AV from '../../AVCore/AVCore';
import AvatarWhite from '../../assets/icons/avatar-whitebg.svg';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import _ from 'lodash';
import { Link } from '../../AVCore/Link';
import helping from '../../assets/images/helping.png';
import { setEntitydata, setSearch } from '../../Redux/Actions/userActions';
import inform from '../../assets/images/inform.png';
import { Club } from '../../AVCore/Club';
import
{
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    EmailShareButton,
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    TelegramShareButton,
    TelegramIcon,
    RedditShareButton,
    RedditIcon,
    TumblrShareButton,
    TumblrIcon,
} from 'react-share';


const AVPersonaModal = ( { isMember, setShowAddProfileModal, isStaff, Entity, setEntity, setcontactList, setshowFileuploadModal, setStaffList, setmemberList, isPlayer, setPlayersList, isParticipant, setIsParticipantList, setFilteredParticipantList,showAddProfileModal, isTeam, searchTypes, setOrgList, setGroupList } ) =>
{
    console.log( "avpersonal modal" );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dimensions = Dimensions.get( 'window' );
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const isMobile = dimensions?.width <= 600 || false;
  const isLMobile = dimensions?.width <= 780 || false;
    const [ checkvalue, setcheckvalue ] = useState( true );
    const [ checkvaluedata, setcheckvaluedata ] = useState<any>( '' );
    const [ filteredResults, setfilteredResults ] = useState( [] );
    const [ name, setName ] = React.useState( '' );
    const [ emailAddress, setIsEmailaddress ] = useState( '' );
    const [ phone, setPhone ] = useState( '' );
    const [ Parentname, setParentName ] = React.useState( '' );
    const [ ParentemailAddress, setIsParentEmailaddress ] = useState( '' );
    const [ Parentphone, setParentPhone ] = useState( '' );
    const [ Role, setRole ] = useState( '' );
    const [ showTempList, setshowTempList ] = React.useState( [] );
    const imageInputRef = useRef<HTMLInputElement>( null );
    const [ error, setError ] = useState( '' );
    const [ isValid, setIsValid ] = useState( true );
    const [ isLoader, setIsLoader ] = useState( false );
    const localUserSearch = useSelector( ( state : RootStateOrAny ) => state.user.userSearch );
    const [ guardianLocalFilteredResults, setGuardianLocalFilteredResults ] = React.useState( [] );
    const [ showSearch, setShowSearch ] = React.useState( false );
    const [ searchInput, setSearchInput ] = React.useState( '' );
    const [ GuardianFilteredResults, setGuardianFilteredResults ] = React.useState( [] );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const [ teamsList, setTeamsList ] = React.useState<any>( [] );
    const [ searchedorg, setSearchedOrg ] = React.useState( '' );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const [ errorMessage, setErrorMessage ] = useState( '' )
    const [ image, setImage ] = useState<any>( '' );
    const [ isSearchDone, setSearchDone ] = useState(true);


    const customSelectStyle = {
        maxHeight: '50px', // Set your desired height here
        width: '100%',
    };
    const customSelectStyleeee = {
        maxHeight: '50px', // Set your desired height here
    };

    const handleStaffName = ( item ) =>
    {
        setName( item.title );
        setcheckvaluedata( item );
        setcheckvalue( false );
        setfilteredResults( [] );
    };

    const handlefileUpload = async ( e : any ) =>
    {
        setError( '' );
        const fileInput : any = imageInputRef.current;
        let files = Array.from( e?.target?.files );
        // Check file extensions
        const allowedExtensions = [ 'docx', 'xlsx', 'ods', 'odt', 'csv', 'txt' ];
        const validFiles : any = files.filter( ( file : any ) =>
        {
            const fileExtension = file.name.split( '.' ).pop().toLowerCase();
            return allowedExtensions.includes( fileExtension );
        } );

        if ( validFiles.length === 0 )
        {
            setError( Loc.currentLang.menu.invaildFileMsg );
            return;
        }

        let val = AV.Media.getFormData( validFiles );
        let contact = await AV.AVEntity.fileContacts( val, isPlayer ? true : false );
        if ( contact?.length > 0 )
        {
            setcontactList( contact );
            setshowFileuploadModal( true );
        }
        if ( contact?.length === 0 )
        {
            setError( 'Need data for Name,Email and Role' );
        }
        fileInput.value = null;
    };

    const HandleName = ( e ) =>
    {
        e.preventDefault();
        setName( e.target.value );
    };

    const HandleEmailAddress = ( e ) =>
    {
        e.preventDefault();
        setIsEmailaddress( e.target.value );
        if ( emailRegex.test( emailAddress ) )
        {
            setIsValid( true );
        } else
        {
            setIsValid( false );
        }
    };
    const HandlePhone = ( e ) =>
    {
        e.preventDefault();
        setPhone( e.target.value.replace( /[^0-9]/g, '' ) )
        if ( phoneRegex.test( e.target.value ) )
        {
            setIsValid( true );
        } else
        {
            setIsValid( false );
        }
    };


    const handleDrop = async ( e ) =>
    {
        e.preventDefault();

        const doc = e.dataTransfer.files;
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from( doc );
        // Check file extensions
        const allowedExtensions = [ 'docx', 'xlsx', 'ods', 'odt', 'csv', 'txt' ];
        const validFiles : any = files.filter( ( file : any ) =>
        {
            const fileExtension = file.name.split( '.' ).pop().toLowerCase();
            return allowedExtensions.includes( fileExtension );
        } );

        if ( validFiles.length === 0 )
        {
            setError( Loc.currentLang.menu.invaildFileMsg );
            return;
        }

        let val = AV.Media.getFormData( validFiles );
        let contact = await AV.AVEntity.fileContacts( val, isPlayer ? true : false );
        if ( contact?.length > 0 )
        {
            setcontactList( contact );
            setshowFileuploadModal( true );
        }
        if ( contact?.length === 0 )
        {
            setError( 'Need data for Name,Email and Role' );
        }
        fileInput.value = null;
    };

    const handleDragOver = ( e ) =>
    {
        e.preventDefault();
    };

    const HandleParentPhone = ( e ) =>
    {
        e.preventDefault();
        setParentPhone( e.target.value.replace( /[^0-9]/g, '' ) )
        if ( phoneRegex.test( e.target.value ) )
        {
            setIsValid( true );
        } else
        {
            setIsValid( false );
        }
    };

    const handleAddTeamProfile = async () =>
    {
        setIsLoader( true );
        if ( !checkvalue )
        {
            let parent : any
            let Entities : any;
            let cacheentity : any = entitydatalist?.find( ( x : any ) => x?.id == checkvaluedata?.id );
            if ( !cacheentity?.id )
            {
                let entityValue : any = await AV.AVEntity.get( AV.AVEntity.getFk( checkvaluedata ) );
                parent = entityValue.parent;
                Entities = entityValue;
            }
            else
            {
                parent = cacheentity.parent;
            }

            if ( parent?.id )
            {
                setErrorMessage( Loc.currentLang.menu.childOrgValidation )
                setIsLoader( false );
                return;
            }
            if ( cacheentity?.id ? cacheentity : Entities?.staff?.filter( ( x ) => x?.id == currentUser?.id )?.length > 0 )
            {

                let team : any = await Club.addTeam( AV.AVEntity.getFk( Entity ), checkvaluedata );
                let teamslist = teamsList;
                teamsList.unshift( checkvaluedata );
                if ( searchTypes == 'SubGroup' && team?.childOrgs?.length > 0 )
                {
                    setGroupList( team?.childOrgs?.filter( ( x : any ) => x?.type == "Group" ) )
                }
                else
                {
                    if ( team?.childOrgs?.length > 0 )
                    {
                        setOrgList( team?.childOrgs )
                    }
                }
                setSearchInput( '' );
                setcheckvalue( true );
                setcheckvaluedata( '' );
                setShowAddProfileModal( false )
            }
            else
            {
                setErrorMessage( Loc.currentLang.menu.youAreNotAnStaffOfThisEntity )
                setIsLoader( false );
                return
            }
        }
        setIsLoader( false );
    };

    function ValidateStaffs ()
    {
        if ( ( emailAddress !== '' ||  ParentemailAddress !== '' ) )
        {
            return true;
        }
    }

    const menuRefs = useRef( null );
    useOutsideAlerter( menuRefs );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setShowSearch( false );
                }
            }
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    const handleAddProfile = async ( type ) =>
    {
        setIsLoader( true );
        if ( type === 'Staff' && ValidateStaffs() && emailRegex.test( emailAddress ) && checkvalue )
        {
            setErrorMessage( '' )
            let coach : any = { name: Role, rights: { canUpdate: true } };
            let addstaff : any = await AV.OrgBase.addStaff( AV.AVEntity.getFk( Entity ), {
                name: name,
                email: emailAddress,
                phone: phone,
                role: coach,
            } );
            if ( addstaff !== null )
            {
                setEntity( addstaff );
                setStaffList( addstaff?.staff );
                let tempstaff : any = showTempList;
                tempstaff.push( addstaff?.staff[ addstaff?.staff?.length - 1 ] );
                setshowTempList( tempstaff );
            }
            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' );
            setRole( '' );
            setIsLoader( false );
            setcheckvalue( false );
        } else if ( type === 'Staff' && !checkvalue )
        {
            setErrorMessage( '' )
            let coach : any = { name: Role, rights: { canUpdate: true } };
            let addStaff : any = await AV.OrgBase.addStaff( AV.AVEntity.getFk( Entity ), {
                name: name,
                email: emailAddress,
                id: checkvaluedata.id,
                pk: checkvaluedata.pk,
                type: checkvaluedata.type,
                region: checkvaluedata.region,
                phone: phone,
                role: coach,
            } );
            if ( addStaff !== null )
            {
                setEntity( addStaff );
                setStaffList( addStaff?.staff );
                let tempstaff : any = showTempList;
                tempstaff.push( addStaff?.staff[ addStaff?.staff?.length - 1 ] );
                setshowTempList( tempstaff );
            }
            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' );
            setRole( '' );
            setIsLoader( false );
            setcheckvalue( false );
        }
        if ( type == 'Member' && ValidateStaffs() && emailRegex.test( emailAddress ) && checkvalue )
        {
            setErrorMessage( '' )
            let addMember : any = await AV.OrgBase.addMember( AV.AVEntity.getFk( Entity ), {
                name: name,
                email: emailAddress,
                phone: phone,
            } );
            if ( addMember !== null )
            {
                setEntity( addMember );
                setmemberList( addMember?.members );
                let tempstaff : any = showTempList;
                tempstaff.push( addMember?.members[ addMember?.members?.length - 1 ] );
                setshowTempList( tempstaff );
            }
            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' );
            setRole( '' );
            setIsLoader( false );
            setcheckvalue( false );
        } else if ( type == 'Member' && !checkvalue )
        {
            setErrorMessage( '' )
            let addMember : any = await AV.OrgBase.addMember( AV.AVEntity.getFk( Entity ), {
                name: name,
                email: emailAddress,
                id: checkvaluedata.id,
                pk: checkvaluedata.pk,
                type: checkvaluedata.type,
                region: checkvaluedata.region,
                phone: phone,
            } );
            if ( addMember !== null )
            {
                setEntity( addMember );
                setmemberList( addMember?.members );
                let tempstaff : any = showTempList;
                tempstaff.push( addMember?.members[ addMember?.members?.length - 1 ] );
                setshowTempList( tempstaff );
            }
            setName( '' );
            setcheckvalue( true );

        }
        if ( type == 'Player' && ValidateStaffs() && emailRegex.test( emailAddress?.trim()?.length > 0 ? emailAddress : ParentemailAddress) && checkvalue )
        {
            setErrorMessage( '' )
            let newPlayer : any = {
                parentName: Parentname?.trim()?.length > 0 ? Parentname : null,
                parentEmail: ParentemailAddress?.trim()?.length > 0 ? ParentemailAddress : null,
                parentPhone: Parentphone?.trim()?.length > 0 ? Parentphone : null,
                name: name,
                email: emailAddress,
                phone: phone,
            };
            let addplayr : any = await AV.Team.addPlayer( AV.AVEntity.getFk( Entity ), newPlayer );
            if ( addplayr != null )
            {
                setEntity( addplayr );
                setPlayersList( addplayr?.players );
                setmemberList( addplayr?.members );
                let tempstaff : any = showTempList;
                tempstaff.push( addplayr?.players[ addplayr?.players?.length - 1 ] );
                setshowTempList( tempstaff );
            }
            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' );
            setParentName( '' );
            setIsParentEmailaddress( '' );
            setParentPhone( '' );
            setcheckvalue( false );
        } else if ( type == 'Player' && !checkvalue )
        {
            setErrorMessage( '' )
            let addplayer : any = await AV.Team.addPlayer( AV.AVEntity.getFk( Entity ), {
                name: name,
                id: checkvaluedata.id,
                pk: checkvaluedata.pk,
                type: checkvaluedata.type,
                region: checkvaluedata.region,
                email: emailAddress,
                phone: phone,
                parentName: Parentname?.trim()?.length > 0 ? Parentname : undefined,
                parentEmail: ParentemailAddress?.trim()?.length > 0 ? ParentemailAddress : undefined,
                parentPhone: Parentphone?.trim()?.length > 0 ? Parentphone : undefined,
            } );
            if ( addplayer?.id )
            {
                if ( entitydatalist?.filter( ( x : any ) => x?.id === addplayer?.id )?.length > 0 )
                {
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( ( x : any ) => x?.id === addplayer?.id ) ) ] = addplayer
                    dispatch( setEntitydata( entitydatalist ) );
                }
                setEntity( addplayer );
                setPlayersList( addplayer?.players );
                setmemberList( addplayer?.members );
                let tempstaff : any = showTempList;
                tempstaff.push( addplayer?.players[ addplayer?.players?.length - 1 ] );
                setshowTempList( tempstaff );
            }
            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' );
            setParentName( '' );
            setIsParentEmailaddress( '' );
            setParentPhone( '' );
            setcheckvalue( false );
        }
        if ( type == 'Participant' && ValidateStaffs() && emailRegex.test( emailAddress ) && checkvalue )
        {
            let addpartcipants : any = await AV.Event.addParticipant( AV.AVEntity.getFk( Entity ), {
                name: name,
                email: emailAddress,
                phone: phone,
                //position: 'QB',
            } );
            if ( addpartcipants !== null )
            {
                setEntity( addpartcipants );
                setPlayersList( addpartcipants?.participants );
                let tempstaff : any = showTempList;
                tempstaff.push( addpartcipants?.participants[ addpartcipants?.participants?.length - 1 ] );
                setshowTempList( tempstaff );
                //setPlayersAttendingList(addpartcipants?.participants?.filter((x) => x.status !== 'No'));
                //setPlayersNotAttendingList(addpartcipants?.participants?.filter((x) => x.status === 'No'));
                setIsParticipantList( addpartcipants?.participants )
                setFilteredParticipantList( addpartcipants?.participants )
            }

            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' );
        }
        else if ( type == 'Participant' && !checkvalue )
        {
            let addpartcipants : any = await AV.Event.addParticipant( AV.AVEntity.getFk( Entity ), {
                thumb: checkvaluedata?.thumb,
                title: name, id: checkvaluedata.id, pk: checkvaluedata.pk, type: checkvaluedata.type, region: checkvaluedata.region, email: emailAddress, phone: phone,// position: "QB"
            } );
            if ( addpartcipants !== null )
            {
                setEntity( addpartcipants );
                setPlayersList( addpartcipants?.participants );
                let tempstaff : any = showTempList;
                tempstaff.push( addpartcipants?.participants[ addpartcipants?.participants?.length - 1 ] );
                setshowTempList( tempstaff );
                setIsParticipantList( addpartcipants?.participants )
                setFilteredParticipantList( addpartcipants?.participants )
                //setPlayersAttendingList(addpartcipants?.participants?.filter((x) => x.status !== 'No'));
                //setPlayersNotAttendingList(addpartcipants?.participants?.filter((x) => x.status === 'No'));
            }
            setName( '' );
            setIsEmailaddress( '' );
            setPhone( '' ); setcheckvalue( true );
        }
        else if ( emailAddress && !emailRegex.test( emailAddress ) )
        {
            setErrorMessage( Loc.currentLang.menu.inValiedEmail ? Loc.currentLang.menu.inValiedEmail : "Invalid Email" )
        }
        setIsLoader( false );
        setcheckvalue( true );
        setErrorMessage( '' )
    };

    const [ shareUrl, setshareUrl ] = React.useState( 'https://actavivo.com' );

    //React.useEffect( () =>
    //{
    //    fetchUrl();
  async function fetchUrl () {
    if (shareUrl == 'https://actavivo.com') {
      let contact : AV.ITokenProcessRequest = {
        type: isStaff ? 'AddStaff' : isPlayer ? 'AddPlayer' : 'Add',
        entity: AV.AVEntity.getLink(Entity),
      };
      let val : any = await AV.AVEntity.createTokenRequest(contact);
      var URL = `${ window.location.origin }/${ val?.type }/${ val?.token }`
      setshareUrl(URL);
    }
  }
/*    }, [] );*/

    const socialShare = async () =>
    {
        if ( navigator.share )
        {
            let contact : AV.ITokenProcessRequest = {
                type: 'Invite',
                entity: AV.AVEntity.getLink( currentUser ),
            };
            let val : any = await AV.AVEntity.createTokenRequest( contact );
            var URL = `${ window.location.origin }/${ val?.type }/${ val?.token }`;
            const shareData = {
                title: '',
                text: 'Welcome to Actavivo',
                url: shareUrl,
            };
            try
            {
                await navigator.share( shareData );
                // resultPara.textContent = 'MDN shared successfully';
            } catch ( err )
            {
                // resultPara.textContent = `Error: ${err}`;
            }
        } else
        {
            alert( 'cannot share ' );
        }
    };

    const SocialShare = ( shareUrl ) =>
    {
        console.log( shareUrl?.shareUrl );
        const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
        const title = Loc.currentLang.post.inviteMessage;
      const body = Loc.currentLang.post.inviteMessage; // "Hey! Join me on Actavivo. Let's make Actavivo our new favorite!";
      const redditTitle = `${ Loc.currentLang.post.inviteMessage } ${ shareUrl }`;
        return (
          <HStack alignItems={ 'center' } space={ 5 } justifyContent={ 'center' } flexWrap={ 'wrap' } width={ isMobile ? '80%' : isLMobile ? '80%' : '' }>
            <WhatsappShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    separator=':: '
                    className='Demo__some-network__share-button'
                >
                    <WhatsappIcon size={ 35 } round />
                </WhatsappShareButton>
            <FacebookShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    quote={ title }
                    className='Demo__some-network__share-button'
                >
                    <FacebookIcon size={ 35 } round />
                </FacebookShareButton>

            {/*<FacebookMessengerShareButton onClick={ fetchUrl }*/}
            {/*        url={ shareUrl.shareUrl }*/}
            {/*        appId='155052346752370'*/}
            {/*        className='Demo__some-network__share-button'*/}
            {/*    >*/}
            {/*        <FacebookMessengerIcon size={ 35 } round />*/}
            {/*    </FacebookMessengerShareButton>*/}

            <LinkedinShareButton url={ shareUrl.shareUrl } className='Demo__some-network__share-button' onClick={ fetchUrl }>
                    <LinkedinIcon size={ 35 } round />
                </LinkedinShareButton>

            <TwitterShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    className='Demo__some-network__share-button'
                >
                    <TwitterIcon size={ 35 } round />
                </TwitterShareButton>

            <EmailShareButton
                    url={ shareUrl.shareUrl }
                    subject={ title }
                    body={ body }
                    className='Demo__some-network__share-button'
                >
              <EmailIcon size={ 35 } round onClick={ fetchUrl } />
                </EmailShareButton>

            <PinterestShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    media={ `https://actavivo.net/wp-content/uploads/2023/08/homebanner.png` }
                    className='Demo__some-network__share-button'
                >
                    <PinterestIcon size={ 35 } round />
                </PinterestShareButton>

            <TelegramShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    className='Demo__some-network__share-button'
                >
                    <TelegramIcon size={ 35 } round />
                </TelegramShareButton>

            {/*<RedditShareButton onClick={ fetchUrl }*/}
            {/*        url={ shareUrl.shareUrl }*/}
            {/*  title={ redditTitle }*/}
            {/*        windowWidth={ 660 }*/}
            {/*        windowHeight={ 460 }*/}
            {/*        className='Demo__some-network__share-button'*/}
            {/*    >*/}
            {/*        <RedditIcon size={ 35 } round />*/}
            {/*    </RedditShareButton>*/}

            <TumblrShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    className='Demo__some-network__share-button'
                >
                    <TumblrIcon size={ 35 } round />
                </TumblrShareButton>
            </HStack>
        );
    };

    const handlesearchTeams = async ( e ) =>
    {
        setSearchDone( true )
        if ( !isTeam )
        {
            let localsearch = await AV.Person.localSearch( e );
            let locallySearch = localUserSearch;
            if ( locallySearch == undefined )
            {
                locallySearch = [];
            }
            locallySearch = locallySearch.concat( localsearch );
            locallySearch = _.uniqBy( locallySearch, 'id' );
            locallySearch = locallySearch?.filter( x => x?.type == 'Person' )
            locallySearch = locallySearch?.filter(
                ( x ) =>
                ( x?.title !== undefined &&
                    x?.title?.toLocaleLowerCase().includes( e.toLocaleLowerCase() ) )
            )

            if ( isStaff )
            {
                locallySearch = locallySearch?.filter(
                    ( item1 ) => !Entity?.staff?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            else if ( isMember )
            {
                locallySearch = locallySearch?.filter(
                    ( item1 ) => !Entity?.members?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            else if ( isParticipant )
            {
                locallySearch = locallySearch?.filter(
                    ( item1 ) => !Entity?.participants?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            else
            {
                locallySearch = locallySearch?.filter(
                    ( item1 ) => !Entity?.players?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            locallySearch = locallySearch?.filter(
                ( item1 ) => !currentUser?.blocked?.some( ( item2 ) => item2?.id === item1?.id )
            );

            setGuardianLocalFilteredResults( locallySearch?.filter( ( x ) => x?.type === 'Person' ) );
            setShowSearch( true );
            setSearchInput( e );
            setName( e )
        }
        else
        {
            setErrorMessage( '' )
            let localsearch = currentUser?.orgs;
            localsearch = localsearch?.filter(
                ( x ) =>
                ( x?.title !== undefined &&
                    x.type == "Team" &&
                    x.subType !== "Club" &&
                    x?.title?.toLocaleLowerCase().includes( e.toLocaleLowerCase() ) )
            )
            if ( searchTypes == 'Group' )
            {
                setGuardianLocalFilteredResults( localsearch?.filter( ( x ) => x?.type === 'Group' && x?.subType == undefined) );
            }
            else if (searchTypes == 'Organization' )
            {
                setGuardianLocalFilteredResults( localsearch?.filter( ( x ) => x?.type === 'Team' || x?.type === 'Group' || x?.type === 'GradClass' || x?.type === 'LeagueTeam' ) );
            }
            setShowSearch( true );
            setSearchInput( e );
            setName( e )

        }
        if ( e?.trim()?.length === 0 )
        {
            setShowSearch( false );
        }
    };
    const searchGuardian = async ( searchValue, isStaff ) =>
    {
        setSearchDone(true)
        setSearchInput( searchValue );
        setErrorMessage( '' )
        if ( searchValue !== '' && !isTeam )
        {
            let searchdata = await AV.Person.search( searchValue, 'Person' );
            let locSearch = localUserSearch;
            if ( locSearch == undefined )
            {
                locSearch = [];
            }
            locSearch = locSearch.concat( searchdata );
            locSearch = _.uniqBy( locSearch, 'id' );
            locSearch = locSearch?.filter( x => x?.type == 'Person' )
            dispatch( setSearch( locSearch ) );
            locSearch = locSearch?.filter(
                ( x ) =>
                ( x?.title !== undefined &&
                    x?.title?.toLocaleLowerCase().includes( searchValue.toLocaleLowerCase() ) )
            )
            if ( isStaff )
            {
                locSearch = locSearch?.filter(
                    ( item1 ) => !Entity?.staff?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            else if ( isMember )
            {
                locSearch = locSearch?.filter(
                    ( item1 ) => !Entity?.members?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            else if ( isParticipant )
            {
                locSearch = locSearch?.filter(
                    ( item1 ) => !Entity?.participants?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            else
            {
                locSearch = locSearch?.filter(
                    ( item1 ) => !Entity?.players?.some( ( item2 ) => item2?.id === item1?.id )
                );
            }
            locSearch = locSearch?.filter(
                ( item1 ) => !currentUser?.blocked?.some( ( item2 ) => item2?.id === item1?.id )
            );

            setGuardianFilteredResults( locSearch );
            setGuardianLocalFilteredResults( locSearch );
            setShowSearch( true );
            setSearchDone(false)
        }

        else if ( isTeam )
        {
            let localsearch = await AV.AVEntity.search( searchValue, 'Org' );
            let locallySearch = localUserSearch;
            if ( locallySearch == undefined )
            {
                locallySearch = [];
            }
            locallySearch = locallySearch.concat( localsearch );
            locallySearch = locallySearch.concat( currentUser?.orgs )
            locallySearch = _.uniqBy( locallySearch, 'id' );
            locallySearch = locallySearch?.filter( x => x?.type !== 'Person' )
            locallySearch = locallySearch?.filter(
                ( x ) =>
                ( x?.title !== undefined &&
                    x?.title?.toLocaleLowerCase().includes( searchValue.toLocaleLowerCase() ) )
            )
            if ( searchTypes == 'SubGroup' || searchTypes == 'Group' )
            {
                setGuardianLocalFilteredResults( locallySearch?.filter( ( x ) => x?.type === 'Group' && x?.subType == undefined) );
            }
            else if ( searchTypes == 'Network' )
            {
                setGuardianLocalFilteredResults( locallySearch?.filter( ( x ) => x?.type === 'Team' || x?.type === 'Group' || x?.type === 'GradClass' || x?.type === 'LeagueTeam' ) );
            }
            setShowSearch( true );
            setSearchInput( searchValue );
            setName( searchValue )

        }
        else
        {
            setSearchInput( '' );
            setGuardianFilteredResults( [] );
            setGuardianLocalFilteredResults( [] );
        }
        setSearchDone( false )
    };

    const handleAddTeam = async ( target ) =>
    {
        setSearchInput( target?.title );
        setName( target?.title );
        setcheckvalue( false );
        setcheckvaluedata( target );
    };

    const clearValue = () =>
    {
        setSearchInput( '' );
        setShowSearch( false )
        setcheckvalue( true );
        setcheckvaluedata( '' );
        setGuardianFilteredResults( [] );
        setGuardianLocalFilteredResults( [] );
    };


    const RenderalertTab : any = useCallback(
        ( GuardianFilteredResults, index ) =>
        {
            let item = GuardianFilteredResults.item;
            let uri = Link.getTummb( item );
            let position = '';
            if ( !uri )
            {
                position = 'absolute';
            }
            return (
                <ScrollView
                    key={ index }
                    showsHorizontalScrollIndicator={ false }
                    maxHeight={ dimensions.height - 150 }
                >
                    <Pressable

                        key={ item.id }
                        padding={ '10px' }
                        _hover={ { bgColor: 'AVColor.secondary' } }
                        onPress={ () =>
                        {
                            handleAddTeam( item );
                            setShowSearch( false );
                            setSearchInput( '' );
                        } }
                    >
                        <Box>
                            <HStack>
                                <Box h={ '45px' } w={ '45px' } borderRadius={ 'full' } overflow={ 'hidden' }>
                                    <Avatar
                                        bg='white'
                                        source={ { uri } }
                                        size='40px'
                                        style={ { width: '100%', height: '100%', backgroundColor: '#71717a' } }
                                    >
                                        <Image source={ AvatarWhite } alt='Actavivo' size='35px' />
                                    </Avatar>
                                </Box>
                                <VStack>

                                    <Text
                                        fontWeight='bold'
                                        numberOfLines={ 1 }
                                        text-overflow={ 'ellipsis' }
                                        width={ '175px' }
                                        paddingLeft={ '10px' }
                                    >
                                        { item.title }
                                    </Text>
                                </VStack>
                            </HStack>
                        </Box>
                    </Pressable>
                </ScrollView>
            );
        },
        [ GuardianFilteredResults ]
    );

    React.useEffect( () =>
    {
        if ( Entity?.roles && Entity?.roles[ 0 ] )
        {
            let data = Entity?.roles[ 0 ]?.name;
            setRole( data )
        }

    }, [ showAddProfileModal ] )

    const defaultButtonDisabled = ( checkvalue ? !name || !emailAddress || ( !emailRegex.test( emailAddress ) ) : !name );


    return (
        <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
            <div
                style={ {
                    position: 'fixed',
                    top: '53%',
                    left: '50%',
                    transform: 'translate(-53%, -50%)',
                    background: '#ffffff',
                    boxShadow:
                        '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                    width: isMobile ? '88%' : '68%',
                    borderRadius: '8px',
                    height: '75%',
                    overflowY: 'auto'
                } }
            >
                <VStack flex={ 1 } width={ '100%' } >
                    <HStack
                        alignItems={ 'center' }
                        justifyContent={ 'space-between' }
                        borderBottomColor={ 'AVColor.grey' }
                        px={ 5 }
                        zIndex={ 99 }
                        width={ '100%' }
                    >
                        <Text fontSize={ 24 }>
                            { ( !isMember && isTeam && Entity?.type === "LeagueTeam" && Entity?.subType == 'Class' ) ? Loc.currentLang.menu.group : ( isMember && Entity?.type === "LeagueTeam" && Entity?.subType == 'Class' ) ? Loc.currentLang.menu?.addParents : ( isStaff && Entity?.type === "LeagueTeam" && Entity?.subType == 'Class') ? Loc.currentLang.menu.addStaff : Entity?.subType == 'Class' && isMember ?
                  Loc.currentLang.menu?.addParents : isMember && Entity?.type !== 'Neighborhood' && Entity?.type !== "School" ? Loc.currentLang.menu.addMember : isPlayer && !isMember && !isStaff && Entity?.subType !== 'Class' && Entity?.subType != 'Term' ? Loc.currentLang.menu.addPlayers : Entity?.subType == 'Term' && isPlayer ? Loc.currentLang.menu.addMember : isPlayer && !isMember && !isStaff && Entity?.subType == 'Class' ? 'Add Student' : isStaff && Entity?.type == 'Neighborhood' ? "Add Administration" : isMember && Entity?.type == 'Neighborhood' ? "Add Neighbors" : Entity?.type === 'Club' && isTeam ? Loc.currentLang.menu.teams : isTeam ? searchTypes : isParticipant ? Loc.currentLang.menu.addParticipants :
                                    Entity?.type === "School" && isStaff ? "Add Administration" : Entity?.type === "School" && isMember ? "Add Staff" : 
                                        Loc.currentLang.menu.addStaff }
                        </Text>
                        <HStack>
                            <HStack height={ '30px' }>
                                <AVCancel callback={ () => setShowAddProfileModal( false ) } color={ undefined } size={ undefined } />
                            </HStack>
                        </HStack>
                    </HStack>
                    <Divider zIndex={ -1 } marginY={ 3 } />
                    {/* if teams */ }
                    { ( isStaff || isMember || isPlayer || isParticipant ) && !isTeam && (
                        <>
                            <HStack
                                alignItems={ 'center' }
                                justifyContent={ checkvalue ? 'space-between' : 'flex-start' }
                                borderBottomColor={ 'AVColor.grey' }
                                px={ 5 }
                                zIndex={ 99 }
                                width={ '100%' }
                                space={ 3 }
                                flexWrap={ 'wrap' }
                            >
                                {/*Name*/ }
                                <Box
                                    ref={ menuRefs }
                                    marginBottom={ '10px' }
                                    width={ isMobile ? '100%' : !isStaff ? '25%' : '20%' }                                >
                                   
                                    <AVInputtext
                                        type={ 'text' }
                                        label={ Loc.currentLang.menu.nameReq }
                                        onChange={ ( e ) => handlesearchTeams( e?.target?.value ) }
                                        value={ name }
                                        height={ '15px' }
                                        width={ undefined}
                                        maxLength={ 50 }
                                        defaultValue={ undefined }
                                        minRows={ undefined }
                                        multiline={ undefined }
                                        size={ undefined }
                                        placeholder={ undefined } />
                                </Box>

                                {/*Email&Phone*/ }
                                { checkvalue && (
                                    <>
                                        <Box marginBottom={ '10px' }
                                            width={ isMobile ? '100%' : !isStaff ? '25%': '20%' }>
                                            <AVInputtext
                                                type={ 'text' }
                                                label={ Loc.currentLang.menu.emailAddress }
                                                onChange={ HandleEmailAddress }
                                                value={ emailAddress }
                                                height={ '15px' }
                                                width={ undefined }
                                                maxLength={ 50 }
                                                defaultValue={ undefined }
                                                minRows={ undefined }
                                                multiline={ undefined }
                                                size={ undefined }
                                                placeholder={ undefined } />
                                        </Box>
                                        <Box marginBottom={ '10px' } width={ isMobile ? '100%' : !isStaff ? '25%' : '20%' }>
                                            <AVInputtext
                                                type={ 'text' }
                                                label={ Loc.currentLang.menu.phone }
                                                onChange={ HandlePhone }
                                                value={ phone }
                                                height={ '15px' }
                                                width={ undefined }
                                                maxLength={ 50 }
                                                defaultValue={ undefined }
                                                minRows={ undefined }
                                                multiline={ undefined }
                                                size={ undefined }
                                                placeholder={ undefined }
                                            />

                                        </Box>
                                    </>
                                ) }
                                {/*Role Dropdown*/ }
                                { ( !isMember && !isPlayer && !isParticipant ) && (
                                    <Box marginBottom={ '9px' } width={ isMobile ? '100%' : '20%' }>
                                        <FormControl fullWidth>
                                            <InputLabel id='demo-simple-select-label'>
                                                { Loc.currentLang.menu.assignRole } *
                                            </InputLabel>
                                            <Select
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                value={ Role }
                                                label={ Loc.currentLang.menu.assignRole }
                                                onChange={ ( e ) => setRole( e.target.value ) }
                                                style={ isMobile ? customSelectStyle : customSelectStyleeee }
                                            >
                                                { Entity?.roles?.map( ( option ) => (
                                                    <MenuItem key={ option?.name } value={ option?.name }>
                                                        { option?.name }
                                                    </MenuItem>
                                                ) ) }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                ) }
                                {/*Searched value*/ }
                                { !checkvalue && (
                                    <Box p={ 1 } backgroundColor={ 'AVColor.hovercolor2' } borderRadius={ 'full' }>
                                        <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 } px={ 2 }>
                                            <ProfilePic width={ 50 } height={ 50 } link={ checkvaluedata } />
                                            <VStack>
                                                <Text
                                                    minW={ '0' }
                                                    maxW={ '200px' }
                                                    text-overflow={ 'ellipsis' }
                                                    numberOfLines={ 1 }
                                                    fontWeight='bold'
                                                >
                                                    { checkvaluedata.title }
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </Box>
                                ) }
                                {/*To Add*/ }
                                <VStack>
                                    <HStack space={ 5 }> 
                                        <Button
                                            bgColor={ 'AVColor.primary' }
                                            borderRadius={ 'full' }
                                            padding={ 2 }
                                            isDisabled={ isMember ? defaultButtonDisabled : isStaff ? !name || !Role : isPlayer ? !name || ( !emailAddress && ( !Parentname || !ParentemailAddress ) ) && checkvalue : !name }
                                        isLoading={ isLoader }
                                        onPress={ () => handleAddProfile( isMember ? 'Member' : isPlayer && !isMember ? 'Player' : isParticipant ? 'Participant' : 'Staff' ) }

                                    >
                                        <AddIcon size={ '20px' } color='AVColor.white' />
                                    </Button>
                                    { !checkvalue && (
                                        <Button
                                            alignSelf={ isMobile ? 'center' : '' }
                                                _hover={ { bgColor: 'AVColor.grey', borderRadius: 'sm' } }
                                                rounded={ 'full' }
                                            bgColor={ 'gray.100' }
                                            borderRadius={ 'full' }
                                            padding={ 2 }
                                                onPress={ () =>
                                                {
                                                    setcheckvalue( true );
                                                    setcheckvaluedata( '' );
                                                    setName('');
                                                } }
                                        >
                                            <CloseIcon size={ '20px' } color='black' />
                                        </Button>
                                        ) }
                                    </HStack>
                                </VStack>
                            </HStack>
                        </>
                    ) }
                    { isTeam && !isMember && !isPlayer && !isParticipant && !isStaff && (
                        //  <TeamSearch orgFK={ orgFK }  { ...props } />
                        <VStack justifyContent={ 'center' }>
                            <HStack alignItems={ 'center' } justifyContent={ 'center' }>
                                <Input
                                    placeholder={ Loc.currentLang.menu.search }
                                    placeholderTextColor='gray.500'
                                    top={ '0px' }
                                    ml={ '2px' }
                                    rounded='full'
                                    variant='AVSearchInput'
                                    width='290px'
                                    height='40px'
                                    fontSize={ '16px' }
                                    borderRadius='10'
                                    p={ 2 }
                                    backgroundColor={ '#EEEEEE' }
                                    borderColor={ 'gray.200' }
                                    InputLeftElement={
                                        <SearchIcon
                                            ml='2'
                                            size='24px'
                                            color='black'
                                            left={ '2px' }
                                            marginRight={ '10px' }
                                            alignSelf={ 'center' }
                                        />
                                    }
                                    value={ searchInput }
                                    onChangeText={ ( e ) => handlesearchTeams( e ) }
                                />
                                { !checkvalue && (
                                    <HStack position={ 'absolute' } right={ '40px' }>
                                        <Box borderRadius={ 'full' } right={ '40px' }>
                                            <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 }>
                                                <ProfilePic width={ 50 } height={ 50 } link={ checkvaluedata } />
                                                <VStack>
                                                    <Text
                                                        minW={ '0' }
                                                        maxW={ '130px' }
                                                        text-overflow={ 'ellipsis' }
                                                        numberOfLines={ 1 }
                                                        fontWeight='bold'
                                                    >
                                                        { checkvaluedata.title }
                                                    </Text>
                                                    {/*<Text fontSize={'12px'}>{checkvaluedata.blurb}</Text>*/ }
                                                </VStack>
                                            </HStack>
                                        </Box>
                                        <HStack space={2}>
                                        <Button
                                            bgColor={ 'AVColor.primary' }
                                            borderRadius={ 'full' }
                                            right={ '25px' }
                                            height={ '35px' }
                                            top={ '10px' }
                                            padding={ 2 }
                                            isLoading={ isLoader }
                                            isDisabled={ checkvalue }
                                            onPress={ () => handleAddTeamProfile() }
                                        >
                                            <AddIcon size={ '20px' } color='AVColor.white' />
                                            </Button>
                                            {!checkvalue && <Button
                                                bgColor={'grey.200'}
                                                borderRadius={ 'full' }
                                                right={ '25px' }
                                                height={ '35px' }
                                                top={ '10px' }
                                                padding={ 2 }
                                                onPress={ () =>
                                                {
                                                    setcheckvalue( true );
                                                    setcheckvaluedata( '' );
                                                    setName( '' );
                                                } }
                                            >
                                                <CloseIcon size={ '18px' } color='black' />
                                            </Button>}
                                        </HStack>
                                    </HStack>
                                ) }

                            </HStack>
                            <HStack justifyContent={ 'center' }>
                                { ( showSearch && isTeam ) && (
                                    <>
                                        <Box
                                            ref={ menuRefs }
                                            background={ '#ffffff' }
                                            borderRadius='md'
                                            shadow='9'
                                            top={ '10px' }
                                            height={ 'auto' }
                                            maxHeight={ '250px' }
                                            width={ '280px' }
                                            zIndex={ 999999 }
                                        >
                                            { guardianLocalFilteredResults?.length !== 0 ? (
                                                <VStack zIndex={ 999999 }>
                                                    <HStack space={ 2 } bgColor={ 'AVColor.white' } zIndex={ 999999 }>
                                                        <HStack>
                                                            <Text color={ '#818181' } fontWeight={ 'bold' }>
                                                                { Loc.currentLang.menu.suggestions }
                                                            </Text>
                                                            <Pressable onPress={ () => clearValue() }>
                                                                <HStack justifyContent={ 'flex-end' } left={ '150px' }>
                                                                    <CrossIcon size='32px' color='#000000' alignSelf={ 'center' } />
                                                                </HStack>
                                                            </Pressable>
                                                        </HStack>
                                                    </HStack>
                                                </VStack>
                        ) : <HStack alignSelf={ "center" }>
                          <Text variant={ 'AVSearchTitleText' }>{ Loc.currentLang.menu.noRecordsFound }</Text>
                        </HStack> }
                                            <FlatList
                                                removeClippedSubviews={ false }
                                                scrollEnabled={ true }
                                                disableVirtualization={ true }
                                                data={ guardianLocalFilteredResults }
                                                renderItem={ RenderalertTab }
                                                // renderItem={(item, index) => <RenderalertTab {...item} key={index} />}
                                                initialNumToRender={ 5 }
                                                maxToRenderPerBatch={ 5 }
                                                windowSize={ 3 }
                                                onEndReachedThreshold={ 0.1 }
                                                keyExtractor={ ( item : any ) => item?.id }
                                            />
                                            {isSearchDone  && (<VStack>
                                                <VStack
                                                    ref={ menuRefs }
                                                    space={ 2 }
                                                    borderRadius='md'
                                                    shadow='9'
                                                    width={ '275px' }
                                                    m={ 1 }
                                                    p={ 2.5 }
                                                    mt={ '4px' }
                                                    bgColor={ '#ffffff' }
                                                    zIndex={ 999 }
                                                    position={ 'fixed' }
                                                >
                                                    <Pressable onPress={ () => searchGuardian( searchInput, isStaff ) }>
                                                        <HStack space={ 10 }>
                                                            <SearchIcon size={ 'AVIconSmall' } color={ '#18B0C2' } />
                                                            <Text fontWeight={ 'bold' } color={ '#18B0C2' }>
                                                                Search
                                                            </Text>
                                                        </HStack>
                                                    </Pressable>
                                                </VStack>
                                            </VStack>)}
                                        </Box>
                                    </>
                                ) }
                            </HStack>
                        </VStack>

                    ) }
                    { ( showSearch && !isTeam ) && (
                        <>
                            <Box
                                ref={ menuRefs }
                                background={ '#ffffff' }
                                position={ 'absolute' }
                                borderRadius='md'
                                shadow='9'
                                left={ isTeam ? '360px' : '15px' }
                                top={ '115px' }
                                height={ 'auto' }
                                maxHeight={ '250px' }
                                width={ '280px' }
                                zIndex={ 999999 }
                            >
                                { guardianLocalFilteredResults?.length !== 0 ? (
                                    <VStack zIndex={ 999999 }>
                                        <HStack space={ 2 } bgColor={ 'AVColor.white' } zIndex={ 999999 }>
                                            <HStack>
                                                <Text color={ '#818181' } fontWeight={ 'bold' }>
                                                    { Loc.currentLang.menu.suggestions }
                                                </Text>
                                                <Pressable onPress={ () => clearValue() }>
                                                    <HStack justifyContent={ 'flex-end' } left={ '150px' }>
                                                        <CrossIcon size='32px' color='#000000' alignSelf={ 'center' } />
                                                    </HStack>
                                                </Pressable>
                                            </HStack>
                                        </HStack>
                                    </VStack>
                  ) : <HStack alignSelf={ "center" }>
                    <Text variant={ 'AVSearchTitleText' }>{ Loc.currentLang.menu.noRecordsFound }</Text>
                  </HStack>}
                                <FlatList
                                    removeClippedSubviews={ false }
                                    scrollEnabled={ true }
                                    disableVirtualization={ true }
                                    data={ guardianLocalFilteredResults }
                                    renderItem={ RenderalertTab }
                                    // renderItem={(item, index) => <RenderalertTab {...item} key={index} />}
                                    initialNumToRender={ 5 }
                                    maxToRenderPerBatch={ 5 }
                                    windowSize={ 3 }
                                    onEndReachedThreshold={ 0.1 }
                                    keyExtractor={ ( item : any ) => item?.id }
                                />
                                <VStack>
                                    <VStack
                                        ref={ menuRefs }
                                        space={ 2 }
                                        borderRadius='md'
                                        shadow='9'
                                        width={ '275px' }
                                        m={ 1 }
                                        p={ 2.5 }
                                        mt={ '4px' }
                                        bgColor={ '#ffffff' }
                                        zIndex={ 999 }
                                        position={ 'fixed' }
                                    >
                                        <Pressable onPress={ () => searchGuardian( searchInput, isStaff ) }>
                                            <HStack space={ 10 }>
                                                <SearchIcon size={ 'AVIconSmall' } color={ '#18B0C2' } />
                                                <Text fontWeight={ 'bold' } color={ '#18B0C2' }>
                                                    Search
                                                </Text>
                                            </HStack>
                                        </Pressable>
                                    </VStack>
                                </VStack>
                            </Box>
                        </>
                    ) }

                    { isPlayer && !isTeam && ( <HStack
                        alignItems={ 'center' }
                        justifyContent={ checkvalue ? 'space-between' : 'flex-start' }
                        borderBottomColor={ 'AVColor.grey' }
                        px={ 5 }
                        zIndex={ -3 }
                        width={ '100%' }
                        space={ 3 }
                        flexWrap={ 'wrap' }
                    >
                        {/*Name*/ }
                        <Box width={ isMobile ? '245px' : '25%' }
                            ref={ menuRefs }
                            marginBottom={ '10px' }>
                            { checkvalue && ( <AVInputtext
                                type={ 'text' }
                                label={ Loc.currentLang.menu.parentName }
                                onChange={ ( e ) => setParentName( e?.target?.value ) }
                                value={ Parentname }
                                height={ '15px' }
                                width={ undefined }
                                maxLength={ 50 }
                                defaultValue={ undefined }
                                minRows={ undefined }
                                multiline={ undefined }
                                size={ undefined }
                                placeholder={ undefined } /> ) }
                        </Box>

                        {/*Email&Phone*/ }
                        { checkvalue && (
                            <>
                                <Box marginBottom={ '10px' } width={ isMobile ? '245px' : '25%' }>
                                    <AVInputtext
                                        type={ 'text' }
                                        label={ Loc.currentLang.menu.parentEmailAddress }
                                        onChange={ ( e ) => setIsParentEmailaddress( e?.target?.value ) }
                                        value={ ParentemailAddress }
                                        height={ '15px' }
                                        width={ undefined }
                                        maxLength={ 50 }
                                        defaultValue={ undefined }
                                        minRows={ undefined }
                                        multiline={ undefined }
                                        size={ undefined }
                                        placeholder={ undefined } />
                                </Box>
                                <Box marginBottom={ '10px' } width={ isMobile ? '245px' : '25%' }>
                                    <AVInputtext
                                        type={ 'text' }
                                        label={ Loc.currentLang.menu.parentPhone }
                                        onChange={ HandleParentPhone }
                                        value={ Parentphone }
                                        height={ '15px' }
                                        width={ undefined }
                                        maxLength={ 50 }
                                        defaultValue={ undefined }
                                        minRows={ undefined }
                                        multiline={ undefined }
                                        size={ undefined }
                                        placeholder={ undefined }
                                    />
                                </Box>
                            </>
                        ) }

                        {/*To Add*/ }
                        <VStack bgColor={ 'white' }
                            borderRadius={ 'full' }
                            padding={ 2 }>
                            <HStack height={ '20px' } width={ '20px' }></HStack>
                        </VStack>
                    </HStack> ) }
                    { errorMessage &&
                        <HStack justifyContent={ 'center' }>
                            <Text color={ 'red.500' } fontSize={ '16px' } fontWeight={ 400 }>{ errorMessage }</Text>
                        </HStack>
                    }
                    {/*Created CARD*/ }
                    { !isTeam && <ScrollView
                        showsHorizontalScrollIndicator={ false }
                        nestedScrollEnabled={ true }
                        zIndex={ -3 }
                    >
                        <HStack width='100%' height='auto' zIndex='9999'>
                            { filteredResults.length > 0 && !isMobile && (
                                <Box
                                    width={ '23%' }
                                    position='absolute'
                                    maxHeight='230px'
                                    minHeight='43px'
                                    backgroundColor={ '#f2f2f2' }
                                    background={ '#ffffff' }
                                    borderColor={ 'AVColor.hovercolor2' }
                                    shadow='2'
                                    left={ 4 }
                                    borderRadius='8px'
                                    overflowY='scroll'
                                >
                                    <Box bgColor={ [ 'AVColor.white' ] }>
                                        <ScrollView showsHorizontalScrollIndicator={ false } maxHeight={ '200px' }>
                                            <VStack space={ 3 }>
                                                { filteredResults.map( ( item : any ) => (
                                                    <label key={ item.id } onClick={ ( e ) => handleStaffName( item ) }>
                                                        <HStack space={ 2 } alignItems='center' px={ 3 } py={ 3 }>
                                                            <ProfilePic width={ 50 } height={ 50 } link={ item } />
                                                            <VStack>
                                                                <Text fontWeight='bold'>{ item.title }</Text>
                                                            </VStack>
                                                        </HStack>
                                                    </label>
                                                ) ) }
                                            </VStack>
                                        </ScrollView>
                                    </Box>
                                </Box>
                            ) }
                        </HStack>
                        <View
                            flexDir={ 'row' } marginTop={ '10px' } zIndex={ -3 } alignItems={ 'center' }
                        >
                            <View flex={ 1 } height={ '1px' } zIndex={ -3 } backgroundColor={ '#D3D3D3' } />
                            <View>
                                <Text style={ { width: 50, textAlign: 'center' } }>
                                    { Loc.currentLang.menu.or }
                                </Text>
                            </View>
                            <View flex={ 1 } height={ '1px' } zIndex={ -3 } backgroundColor={ '#D3D3D3' } />
                        </View>

                        <VStack
                borderStyle={ 'dashed' }
                borderColor={ 'gray.300' }
                borderRadius={ '5px' }
                borderWidth={ '1px' }
                padding={ '20px' }
                width={ '80%' }
                alignSelf={ 'center' }
                zIndex={ -3 }
                marginTop={'10px' }
                        >
                            <div
                                style={ { textAlign: 'center' } }
                                onDrop={ handleDrop }
                                onDragOver={ handleDragOver }
                            >
                                <HStack justifyContent={ 'center' } space={ 2 }>

                                    <Pressable onPress={ () => imageInputRef?.current?.click() }>
                                        <HStack space={ 2 }>
                                            <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.uploadFile } </Text>
                                            <Text color={ 'black' } fontWeight={ 500 }>{ Loc.currentLang.menu.or }</Text>
                                            <Text color={ 'black' }>{ Loc.currentLang.menu.dragNDrop }</Text>
                                        </HStack>
                                    </Pressable>

                                </HStack>
                                <Text textAlign={ 'center' } color={ 'gray.300' }>
                                    ({ Loc.currentLang.menu.docExtensions })
                                </Text>
                                { error && (
                                    <Text textAlign={ 'center' } color={ '#DF2827' } marginTop={ '5px' }>
                                        { ' ' }
                                        { error }
                                    </Text>
                                ) }
                            </div>
                        </VStack>

                        { ( isStaff || isMember || isPlayer ) && ( <HStack justifyContent={ 'center' } top={ '50px' }>
                            <SocialShare shareUrl={ shareUrl } />
                        </HStack> ) }
              <HStack width={ isLMobile ? '95%' : isLMobile ? '95%' : '95%' } margin-right={ '5px' } textAlign={ 'justify' } marginTop={ isMobile ? '15%' : isLMobile ? '15%' :  '10%' }>
                            <img
                                src={ inform }
                                style={ {
                                    width: '20px',
                                    height: '20px',
                                    padding: '10px',
                                    marginTop: '-10px',
                                } }
                            />
                            { isStaff ? Loc.currentLang.helpText.addStaff : isMember ? Loc.currentLang.helpText.addMember : isPlayer ? Loc.currentLang.helpText.addPlayer : Loc.currentLang.helpText.addPlayer }
                        </HStack>
                        <input
                            type='file'
                            accept=".txt,.docx,.xlsx,.ods,.odt,.csv"
                            multiple
                            hidden
                            ref={ imageInputRef }
                            onChange={ handlefileUpload }

                        />

                        {
                            <HStack
                                flexWrap={ 'wrap' }
                                alignSelf={ 'flex-start' }
                                zIndex={ -3 }
                                height={ '300px' }
                                overflowY={ 'auto' }
                            >
                                { showTempList?.map( ( item : any ) => (
                                    <Box
                                        key={ item.id }
                                        bgColor={ '#fff' }
                                        borderWidth={ '1px' }
                                        borderColor={ '#e7ebe5' }
                                        margin={ 4 }
                                        overflow='hiden'
                                        minWidth={ 130 }
                                        height={ '150px' }
                                        rounded='lg'
                                        zIndex={ -3 }
                                    >

                                        <VStack alignItems={ 'center' } padding={ 2 } zIndex={ -3 }>
                                            <Pressable
                                                key={ item.id }
                                                zIndex={ -3 }
                                                _hover={ { bgColor: '#EEEEEE' } }
                                                onPress={ () =>
                                                {
                                                    navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                        state: {
                                                            user: {
                                                                id: item?.id,
                                                                pk: item?.pk,
                                                                region: item?.region,
                                                                type: item?.type,
                                                                name: item?.title,
                                                            },
                                                        },
                                                    } );
                                                } }
                                            >
                                                { item?.thumb && (
                                                    <Box
                                                        bgColor={ '#FF6600' }
                                                        borderRadius={ [ 'full' ] }
                                                        width={ '70px' }
                                                        height={ '70px' }
                                                        left={ '20px' }
                                                        p={ 0 }
                                                        zIndex={ -3 }
                                                    >
                                                        <Image
                                                            style={ { height: '100%', width: '100%', borderRadius: 100 } }
                                                            source={ {
                                                                uri: AV.Media.getThumb( item ),
                                                            } }
                                                        />
                                                    </Box>
                                                ) }
                                                { !item?.thumb && (
                                                    <Box
                                                        alignItems={ 'center' }
                                                    >
                                                        <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                    </Box>
                                                ) }
                                                <Pressable
                                                    zIndex={ -3 }
                                                    onPress={ () =>
                                                    {
                                                        navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                            state: {
                                                                user: {
                                                                    id: item?.id,
                                                                    pk: item?.pk,
                                                                    region: item?.region,
                                                                    type: item?.type,
                                                                    name: item?.title,
                                                                },
                                                            },
                                                        } );
                                                    } }
                                                >
                                                    <Text
                                                        marginTop={ 2 }
                                                        left={ '2px' }
                                                        alignItems='center'
                                                        width='120px'
                                                        numberOfLines={ 1 }
                                                        textAlign='center'
                                                        zIndex={ -3 }
                                                    >
                                                        { item.title }
                                                    </Text>
                                                </Pressable>
                                                <Text
                                                    marginTop={ 2 }
                                                    left={ '2px' }
                                                    alignItems='center'
                                                    width='120px'
                                                    numberOfLines={ 1 }
                                                    textAlign='center'
                                                    zIndex={ -3 }
                                                >
                                                    { item?.role?.name }
                                                </Text>

                                            </Pressable>
                                        </VStack>
                                    </Box>
                                ) ) }
                            </HStack>
                        }
                    </ScrollView> }
                </VStack>
            </div>
        </Box>
    )
}

export default AVPersonaModal;