import {
    Box,
    Input,
    Hidden,
    HStack,
    VStack,
    Image,
    Text,
    Pressable,
    Avatar,
    FlatList
} from 'native-base';
import { Loc } from '../../App/Loc';
import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { CrossIcon, SearchIcon } from '../../assets/icons/icon-assets';
import useQuickSearch from '../../Hooks/useQuickSearch';
import { Link } from '../../AVCore/Link';
import { useNavigate } from 'react-router-dom';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { ILink } from '../../AVCore/Interfaces/AV.Interfaces';
import ProfilePic from '../common/ProfilePicture';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import * as AV from '../../AVCore/AVCore';

function QuickSearch () {
    const navigate = useNavigate();
    const {
        showSearchinput,
        setshowSearchinput,
        searchInput,
        setSearchInput,
        showSearch,
        setShowSearch,
        filteredResult,
        searchItems,
        setShowAdvSearch,
        advSearch,
        setAdvFilteredResults,
        setFilteredResults,
        setValue,
        resultData
    } = useQuickSearch();
    const user = useSelector((state : any) => state.user.user[ 0 ]);
    const menuRef = useRef(null);
    const [ isOpen, setIsOpen ] = React.useState(false);
    const dimensional = useWindowDimension();
    const [ showXsSearch, setShowXsSearch ] = React.useState( false );
    const entitydatalist : any = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );


    useOutsideAlerter(menuRef);
    //Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            // Alert if clicked on outside of element
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowSearch(false);
                    setShowAdvSearch(false);
                    setIsOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }
    const clearValue = () => {
        setValue("");
        setShowSearch(false);
        setSearchInput("");
        setFilteredResults([]);
        setAdvFilteredResults([]);
        setIsOpen(false)
    };
    const handleNavigation = ( item ) =>
    {
        if ( item.type === 'Person' )
        {
            navigate( `/profile/${ item.id }/${ item.region }`, {
                state: {
                    user: {
                        id: item?.id,
                        pk: item?.pk,
                        region: item?.region,
                        type: item?.type,
                        name: item?.title,
                    },
                },
            } );
        } else if ( item.type === 'LeagueTeam' || item.type === 'Team' )
        {
            navigate( `/season/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } );
        } else if ( item.id === 'b495d4e0-bf52-4690-8dc0-677db954aa72' || item.id === 'b495d4e0-bf52-4690-8dc0-677db954aa71' || item.id === 'b495d4e0-bf52-4690-8dc0-677db954aa7f' )
        {
            navigate( `/teams/${ item?.id }/${ item?.region }`, {
                state: { isCreate: false, team: item, type: 'Feedback' },
            } );
        } else
        {
            navigate( `/organizations/${ item?.id }/${ item?.region }/${ item?.type }`, {
                state: { isCreate: false, orgType: item?.type, team: item },
            } );
        }
    };
    const isMobile = dimensional?.width <= 690 || false;
    const renderItem = ( { item } : { item : ILink } ) =>
    {
      let cacheItem = entitydatalist?.find( ( x : any ) => x?.id == item?.id );
        if ( cacheItem || currentUser?.id == item?.id )
            item = currentUser?.id == item?.id ? AV.AVEntity.getLink( currentUser ) : AV.AVEntity.getLink( cacheItem )
        return (
            <Pressable
                key={ item.id }
                px={ 5 }
                py={ 2 }
                width={ '100%' }
                _hover={ { bgColor: 'AVColor.buttonBg' } }
                onPress={ () =>
                {
                    setShowSearch( false );
                    setShowAdvSearch( false );
                    setIsOpen( false );
                    handleNavigation( item );
                    setSearchInput( '' );
                } }
            >
                <Box>
              <HStack space={ 2 }>
                <ProfilePic width={ 8} height={ 8 } link={ item } />

						{/*<Avatar*/}
						{/*    bg='white'*/}
						{/*    source={ {*/}
						{/*        uri: Link.getTummb(item),*/}
						{/*    } }*/}
						{/*    width={ '24px' }*/}
						{/*    height={ '24px' }*/}
						{/*>*/}
						{/*    <Image source={ LogoSvg } alt='Actavivo' size='24px' />*/}
						{/*</Avatar>*/}

                        <VStack width={ '95%' }>
                            <Text width={ '90%' } >{ item.title }</Text>
                            {item?.type == "LeagueTeam" && <Text width={ '90%' } >{ item.blurb }</Text>}
                        </VStack>
                    </HStack>
                </Box>
            </Pressable>
        )
    }
    return (
        <VStack>
            { Platform.OS !== 'web' && (
                <Box marginLeft={ [ '24px' ] } flexDirection={ 'row' } alignItems={ 'center' }>
                    <SearchIcon
                        size={ 'AVIconSmall' }
                        color={ '#000000' }
                        onPress={ () => setshowSearchinput(!showSearchinput) }
                    />
                    { showSearchinput && (
                        <Input
                            placeholder={ Loc.currentLang.placeholderText.searchPlaceholder }
                            variant='AVSearchInput'
                            width={ [ 150 ] }
                            padding={ 1 }
                            pl={ 2 }
                            borderWidth={ 1 }
                            borderColor={ 'AVColor.grey' }
                            bgColor={ '#EEEEEE' }
                            InputLeftElement={ <SearchIcon ml='2' size='4' left={ '20px' } /> }
                        />
                    ) }
                </Box>
            ) }
            { Platform.OS === 'web' && (
                <HStack alignItems={ 'center' } padding={ '3px' } >
                    <Hidden till='md'>
                        <Input
                            isDisabled={ user?.status === 'Deleted' }
                            placeholder={ Loc.currentLang.placeholderText.searchPlaceholder }
                            placeholderTextColor="gray.400"
                            ml={ '2px' }
                            rounded='full'
                            variant='AVSearchInput'
                            width='218px'
                            height='40px'
                            fontSize={ '16px' }
                            borderRadius='19px'
                            p={ 2 }
                            onFocus={ () => setIsOpen(true) }
                            backgroundColor={ '#EEEEEE' }
                            borderColor={ 'gray.200' }
                            InputLeftElement={
                                <SearchIcon
                                    ml='2'
                                    size='24px'
                                    color='#000000;'
                                    left={ '2px' }
                                    marginRight={ '10px' }
                                    alignSelf={ 'center' }
                                />
                            }
                            value={ searchInput }
                            onChangeText={ (e) => searchItems(e, '') }
                        />
                    </Hidden>
                    {/* on small screeens */ }
                    <Hidden from={ 'md' }>
                        <Pressable
                            backgroundColor={ 'AVColor.buttonBg' }
                            p={ 1 }
                            rounded={ 'full' }
                            _hover={ { backgroundColor: 'AVColor.grey' } }
                            onPress={ () => setShowXsSearch(!showXsSearch) }
                        >
                            <SearchIcon size='24px' color='AVColor.black' />
                        </Pressable>
                    </Hidden>
                </HStack>
            ) }
            {/* show search on small screen */ }
            { showXsSearch && (
                <Box
                    backgroundColor={ 'AVColor.white' }
                    position={ 'fixed' }
                    left={ 0 }
                    right={ 0 }
                    zIndex={ 99 }
                    top={ 20 }
                    borderBottomWidth={ 1 }
                    borderBottomColor={ 'AVColor.buttonBg' }
                    pb={ 3 }
                >
                    <HStack p={ 2 }>
                        <Input
                            placeholder={ Loc.currentLang.placeholderText.searchPlaceholder }
                            placeholderTextColor="gray.500"
                            ml={ '2px' }
                            rounded='full'
                            variant='AVSearchInput'
                            width='100%'
                            fontSize={ '16px' }
                            borderRadius='10'
                            p={ 2 }
                            backgroundColor={ '#EEEEEE' }
                            borderColor={ 'gray.200' }
                            InputLeftElement={
                                <SearchIcon
                                    ml='2'
                                    size='24px'
                                    color='black'
                                    left={ '2px' }
                                    marginRight={ '10px' }
                                    alignSelf={ 'center' }
                                />
                            }
                            value={ searchInput }
                            onChangeText={ (e) => searchItems(e, '') }
                        />
                    </HStack>
                </Box>
            ) }
            { (showSearch || isOpen) && (
                <>
                    { (
                        <Box
                            background={ '#ffffff' }
                            position={ 'fixed' }
                            mt={ isMobile ? 120 : 12 }
                            py={ 5 }
                            ref={ menuRef }
                            borderRadius={ [ 0, '8px' ] }
                            shadow='2'
                            width={isMobile ? '100%':'322px'}
                            borderColor={ 'AVColor.buttonBg' }
                            borderWidth={ 1 }
                            zIndex={ 2 }
                            right={ isMobile ? 0 : "" }
                        >
                            <>
                                { (filteredResult?.length !== 0 && filteredResult?.length !== undefined) &&
                                    (
                                        <VStack px={ 5 }>
                                            <HStack space={ 2 } bgColor={ "AVColor.white" } zIndex={ 999 } width={ '100%' }>
                                                <HStack width={ '100%' } justifyContent={ 'space-between' }>
                                                <Text variant={ 'AVlinetext' }> { Loc?.currentLang?.quickSearch?.suggestions }</Text>
                                                    <Pressable onPress={ () => clearValue() }>
                                                        <HStack justifyContent={ 'flex-end' }>
                                                            <CrossIcon size='24px' color='#000000' alignSelf={ 'center' } />
                                                        </HStack>
                                                    </Pressable>
                                                </HStack>
                                            </HStack>
                                        </VStack>
                                    )
                                }
                                <HStack flexWrap={ 'wrap' } bgColor={ "AVColor.white" } zIndex={ 999 } width={ '98%' } justifyContent={ 'center' } my={ 2 }>
                                    <FlatList
                                        removeClippedSubviews={ false }
                                        data={ filteredResult }
                                        renderItem={ renderItem }
                                        initialNumToRender={ 5 }
                                        maxToRenderPerBatch={ 5 }
                                        windowSize={ 3 }
                                        onEndReachedThreshold={ 0.1 }
                                        keyExtractor={ (item : any) => item?.id }
                                        maxHeight={ isMobile ? '40vh' : '60vh' }
                                    />
                                </HStack>
                               
                                        <VStack space={ 2 } bgColor={ "AVColor.white" } zIndex={ 999 } width={ '100%' } px={ 5 }>
                                            <HStack alignSelf={ "flex-start" } mb={ 5 }>
                                            <Text variant={ 'AVSearchTitleText' }>{ Loc?.currentLang?.quickSearch?.moreSearchOptions }</Text>
                                            </HStack>
                                            <Pressable onPress={ () => advSearch(searchInput, 'Person') }>
                                                <HStack space={ 5 } alignItems={ 'center' }>
                                                    <SearchIcon size={ "AVIconSmall" } color={ '#00A7BC' } />
                                                <Text variant={ 'AVSearchlinetext' }> { Loc?.currentLang?.quickSearch?.searchByPerson}</Text>
                                                </HStack>
                                            </Pressable  >
                                            <Pressable onPress={ () => advSearch(searchInput, 'Org') }>
                                                <HStack space={ 5 } alignItems={ 'center' }>
                                                    <SearchIcon size={ "AVIconSmall" } color={ '#00A7BC' } />
                          <Text variant={ 'AVSearchlinetext' }>{ Loc?.currentLang?.quickSearch?.searchByorg }</Text>
                                                </HStack>
                                            </Pressable>
                                        </VStack>
                                { resultData &&
                                    (
                                    <VStack space={ 2 } bgColor={ "AVColor.white" } zIndex={ 999 } width={ '100%' } px={ 5 } marginTop={'15px'}>
                                            <HStack alignSelf={ "center" }>
                        <Text variant={ 'AVSearchTitleText' }>{ Loc.currentLang.menu.noRecordsFound }</Text>
                                            </HStack>
                                        </VStack>
                                    )
                                }
                            </>
                        </Box>
                    ) }
                </>
            ) }
        </VStack>
    );
}

export default QuickSearch;
