import React, { useState } from "react";
import { Text, HStack, Button } from "native-base";
import { Loc } from "../../App/Loc";
import AVCancel from "../common/AVCancel";
import AVInputtext from "../AVInputtext";
import { AddIcon } from "assets/icons/icon-assets";
import * as AV from "../../AVCore/AVCore";
import { setUser } from "Redux/Actions/userActions";
import { isMobile as isMobileDevice } from "react-device-detect";
import { Dimensions } from "react-native";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const InviteFriends = ({ showAddinviteModal, setshowAddinviteModal }) => {
	const dispatch = useDispatch();
	const emailRegex = /\S+@\S+\.\S+/;
	const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
	const [personName, setPersonName] = useState("");
	const [personEmail, setPersonEmail] = useState("");
	const [personPhone, setPersonPhone] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);

	const handleSendInvite = async () => {
		if (personEmail?.trim()?.length > 0) {
			if (!emailRegex.test(personEmail)) {
				return;
			}
		}
		setIsLoading(true);
		let contact: AV.IContact = {
			name: personName,
			email: personEmail,
			type: "Person",
			phone: personPhone,
		};
		let val = await AV.Person.createInvite([contact]);
		if (val) {
			console.log(val);
			dispatch(setUser(val));
		}
		setIsLoading(false);
		setPersonName("");
		setPersonEmail("");
		setPersonPhone("");
		//setshowAddinviteModal(false);
	};

	const [shareUrl, setshareUrl] = React.useState(window.location.origin);

	const socialShare = async () => {
		if (navigator.share) {
			let contact: AV.ITokenProcessRequest = {
				type: "Invite",
				entity: AV.AVEntity.getLink(currentUser),
			};
			let val: any = await AV.AVEntity.createTokenRequest(contact);
			var URL = `${window.location.origin}/${val?.type}/${val?.token}`;
			const shareData = {
				title: "",
				text: "Welcome to Actavivo",
				url: shareUrl,
			};
			try {
				await navigator.share(shareData);
				// resultPara.textContent = 'MDN shared successfully';
			} catch (err) {
				// resultPara.textContent = `Error: ${err}`;
			}
		} else {
			alert("cannot share ");
		}
	};
	const dimensions = Dimensions.get("window");
	const isMobile = dimensions?.width <= 600 || false;

	return (
		<div className="modal-blur">
			<div
				className={isMobile ? "modal-container-mobile" : "modal-container"}
				style={{ height: isMobile ? "275px" : "auto" }}>
				<div className="content">
					<div className="header">
						<Text fontSize={24}>{Loc.currentLang.menu?.inviteFriends}</Text>
						<AVCancel color={undefined} size={undefined} callback={() => setshowAddinviteModal(false)} />
					</div>

					<div className="body">
						{/*<div className='customInviteForm'>*/}
						{/*  <AVInputtext*/}
						{/*                  type={'text'}*/}
						{/*                  label={Loc.currentLang.menu.personName}*/}
						{/*                  // label={'Person Name *'}*/}
						{/*                  onChange={(e) => setPersonName(e.target.value)}*/}
						{/*                  value={personName}*/}
						{/*                  size='small' defaultValue={undefined} width={undefined} height={undefined} minRows={undefined} multiline={undefined} maxLength={undefined} min={undefined} max={undefined}              />*/}
						{/*  <AVInputtext*/}
						{/*                  type={'email'}*/}
						{/*                  label={Loc.currentLang.menu.emailAddress}*/}
						{/*                  onChange={(e) => setPersonEmail(e.target.value)}*/}
						{/*                  value={personEmail}*/}
						{/*                  size='small' defaultValue={undefined} width={undefined} height={undefined} minRows={undefined} multiline={undefined} maxLength={undefined} min={undefined} max={undefined}              />*/}
						{/*  <AVInputtext*/}
						{/*                  type={'phone'}*/}
						{/*                  label={Loc.currentLang.menu.phone}*/}
						{/*                  onChange={(e) => setPersonPhone(e.target.value)}*/}
						{/*                  value={personPhone}*/}
						{/*                  size='small' defaultValue={undefined} width={undefined} height={undefined} minRows={undefined} multiline={undefined} maxLength={undefined} min={undefined} max={undefined}              />*/}
						{/*  <Button*/}
						{/*    bgColor={'AVColor.primary'}*/}
						{/*    borderRadius={'full'}*/}
						{/*    isLoading={isLoading}*/}
						{/*    width={'40px'}*/}
						{/*    height={'40px'}*/}
						{/*    isDisabled={personName?.trim()?.length === 0 || personEmail?.trim()?.length === 0}*/}
						{/*    onPress={() => handleSendInvite()}*/}
						{/*  >*/}
						{/*    <AddIcon size={'20px'} color='AVColor.white' />*/}
						{/*  </Button>*/}
						{/*</div>*/}
						<HStack alignItems={"center"} justifyContent={"center"} pt={10} pb={3}>
							{/* <div className='line-break'>---- OR ----</div>*/}
						</HStack>
						{isMobileDevice ? (
							<HStack justifyContent={"center"}>
								<Button bgColor={"AVColor.primary"} rounded="full" px={8} mb={2} onPress={socialShare}>
									Invite friends
								</Button>
							</HStack>
						) : (
							<SocialShare />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const SocialShare = () => {
  const [ shareUrl, setshareUrl ] = React.useState(window.location.origin);
  //React.useEffect(() => {
  //  fetchUrl();
  async function fetchUrl () {
    if (shareUrl == window.location.origin) {
      let contact : AV.ITokenProcessRequest = {
        type: 'Invite',
        entity: AV.AVEntity.getLink(currentUser),
      };
      let val : any = await AV.AVEntity.createTokenRequest(contact);
      var URL = `${ window.location.origin }/${ val?.type }/${ val?.token }`;
      setshareUrl(URL);
    }
  }
/*  }, []);*/
  const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);
  const title = Loc.currentLang.post.inviteMessage;
    const body = Loc.currentLang.post.inviteMessage; // "Hey! Join me on Actavivo. Let's make Actavivo our new favorite!";
    const Media = 'https://images.pexels.com/photos/26151064/pexels-photo-26151064/free-photo-of-packs-of-fresh-strawberries-and-cherries.jpeg'
  const redditTitle = `${ Loc.currentLang.post.inviteMessage } ${ shareUrl }`;
  return (
    <HStack alignItems={'center'} space={5} justifyContent={'center'} flexWrap={'wrap'}>
      <WhatsappShareButton onClick={ fetchUrl }
        url={shareUrl}
        title={title}
        separator=':: '
        className='Demo__some-network__share-button'
      >
        <WhatsappIcon size={35} round />
      </WhatsappShareButton>
      <FacebookShareButton onClick={ fetchUrl }
        url={shareUrl}
        quote={title}
        className='Demo__some-network__share-button'
      >
        <FacebookIcon size={35} round />
      </FacebookShareButton>

      {/*<FacebookMessengerShareButton onClick={ fetchUrl }*/}
      {/*  url={ shareUrl }*/}
      {/*  appId='155052346752370'*/}
      {/*  className='Demo__some-network__share-button'*/}
      {/*>*/}
      {/*  <FacebookMessengerIcon size={35} round />*/}
      {/*</FacebookMessengerShareButton>*/}

			<LinkedinShareButton
				url={shareUrl}
				className="Demo__some-network__share-button"
				onClick={fetchUrl}
				title={title}
				summary={title}
				source={title}>
				<LinkedinIcon size={35} round />
			</LinkedinShareButton>

			<TwitterShareButton onClick={fetchUrl} url={shareUrl} title={title} className="Demo__some-network__share-button">
				<TwitterIcon size={35} round />
			</TwitterShareButton>

			<EmailShareButton
				url={shareUrl}
				subject={title}
				body={body}
				separator=":"
				className="Demo__some-network__share-button">
				<EmailIcon size={35} round onClick={fetchUrl} />
			</EmailShareButton>


      {/*<PinterestShareButton onClick={ fetchUrl }*/}
      {/*  url={ shareUrl }*/}
      {/*  media={`https://actavivo.net/wp-content/uploads/2023/08/homebanner.png`}*/}
      {/*  className='Demo__some-network__share-button'*/}
      {/*>*/}
      {/*  <PinterestIcon size={35} round />*/}
      {/*</PinterestShareButton>*/}

          <PinterestShareButton
              url={ shareUrl }
              media={ Media }
              description={ "description of the image" }
          >
              <PinterestIcon size={ 35 } round={ true } />
          </PinterestShareButton>
      <TelegramShareButton onClick={ fetchUrl }
        url={ shareUrl }
        title={title}
        className='Demo__some-network__share-button'
      >
        <TelegramIcon size={35} round />
      </TelegramShareButton>

      {/*<RedditShareButton onClick={ fetchUrl }*/}
      {/*  url={ shareUrl }*/}
      {/*  title={ redditTitle }*/}
      {/*  windowWidth={660}*/}
      {/*  windowHeight={460}*/}
      {/*  className='Demo__some-network__share-button'*/}
      {/*>*/}
      {/*  <RedditIcon size={35} round />*/}
      {/*</RedditShareButton>*/}

			<TumblrShareButton onClick={fetchUrl} url={shareUrl} title={title} className="Demo__some-network__share-button">
				<TumblrIcon size={35} round />
			</TumblrShareButton>
		</HStack>
	);
};

export default InviteFriends;
