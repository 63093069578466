/*!
 * Copyright © 2024 Actavivo
 */
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { AVEntity } from "./AVEntity";

export class Event extends AVEntity {
	static _eventBaseApi = "/api/Event";

	public static async addParticipants(source: AV.IFK, target: AV.IContact[]): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/AddParticipant", {
			source: source,
			target: target,
			type: "Participant",
		});
	}
	public static async addParticipant(source: AV.IFK, target: AV.IContact): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/AddParticipant", {
			source: source,
			target: [target],
			type: "Participant",
		});
	}
	public static async removeParticipant(source: AV.IFK, target: AV.IContact): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/RemoveParticipant", {
			source: source,
			target: target,
			type: "Participant",
		});
	}
	public static async updateParticipant(source: AV.IFK, target: AV.IParticipantLink): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/UpdateParticipant", { source: source, target: target });
	}
	public static async cancelEvent(Source: AV.IFK): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/Cancel", Source);
	}
	public static async addResource(source: AV.IFK, target: AV.IEventResource): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/AddResource", { source: source, target: target });
	}
	public static async removeResource(source: AV.IFK, target: AV.IEventResource): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/RemoveResource", { source: source, target: target });
	}
	public static async updateResource(source: AV.IFK, target: AV.IEventResource): Promise<AV.IEvent> {
		return await Server.post(this._eventBaseApi + "/UpdateResource", { source: source, target: target });
	}
}
