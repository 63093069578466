import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { EventRenderer, ICalendarEventBase, Calendar as NativeCalendar } from "react-native-big-calendar";

import { Loc } from "App/Loc";
import moment from "moment";
import "moment/min/locales";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, HStack, Stack, Text, VStack } from "native-base";
// import { TODAY, VIEW_OPTIONS, DAYS } from 'Constants';

import { RecursiveArray, TouchableOpacity, ViewStyle } from "react-native";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { RootStateOrAny, useSelector } from "react-redux";
import ShowEvents from "Screens/Profile/ShowEvents";

export const AVCalendarView = ({ data, eventType }) => {

	const currentUser = useSelector((state: RootStateOrAny) => state?.user?.user[0]);


	moment.locale(Loc.currentLang.loc);
	const navigate = useNavigate();

	const Views = {
		MONTH: "month",
		WEEK: "week",
		DAY: "day",
	};

	const calendar = Loc.currentLang && Loc.currentLang?.calendar;
	const VIEW_OPTIONS = [
		{ id: "month", label: calendar.month },
		{ id: "week", label: calendar.week },
		{ id: "day", label: calendar.day },
	];

	const TODAY = calendar.today;

	const DAYS = [calendar.sun, calendar.mon, calendar.tue, calendar.wed, calendar.thu, calendar.fri, calendar.sat];

	const [date, setDate] = useState<Date>(moment().toDate());
	const [view, setView] = useState(Views.MONTH);
	const [eventsData, setEventsData] = useState([]);
	const [scrollOffSet, setScrollOffSet] = useState(420);
	// const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	useEffect(() => {
		if (data) {

			let filtered = data?.filter(eve => {
				if (eventType === 'All' || eventType === eve.linkType) return eve;

			});


			let events = filtered.map(event => {
				let status = currentUser?.events?.filter(x => x?.id === event?.id);

				let isCanceled = false;
				let rsvp = false;
				if (status?.length > 0 && status[0].status === 'Canceled') {
					isCanceled = true
				}
				if (status?.length > 0 && status[0].status === 'Yes') {
					rsvp = true;
				}
				return {
					id: event?.id,
					start: moment(event.start).toDate(),
					end: moment(event.end).toDate(),
					title: event?.title || event?.name,
					eventType: event?.linkType || event?.eventType,
					description: event?.blurb || "",
					data: event,
					status: isCanceled,
					rsvp: rsvp,
					statusText: isCanceled ? status[0].status : ''
				};
			});

			events?.length && setEventsData(events.filter((e: { status: boolean; rsvp: boolean }) => e.status !== true && e.rsvp === true))
		} else {
			setEventsData([])
		}
	}, [data, eventType]);

	const onPrevClick = useCallback(() => {
		if (view === Views.DAY) {
			setDate(moment(date).subtract(1, "d").toDate());
		} else if (view === Views.WEEK) {
			setDate(moment(date).subtract(1, "w").toDate());
		} else {
			setDate(moment(date).subtract(1, "M").toDate());
		}
	}, [view, date]);

	const onNextClick = useCallback(() => {
		if (view === Views.DAY) {
			setDate(moment(date).add(1, "d").toDate());
		} else if (view === Views.WEEK) {
			setDate(moment(date).add(1, "w").toDate());
		} else {
			setDate(moment(date).add(1, "M").toDate());
		}
	}, [view, date]);

	const dateText = useMemo(() => {
		if (view === Views.DAY) {
			return moment(date).format("dddd, MMMM DD");
		}
		if (view === Views.WEEK) {
			const from = moment(date)?.startOf("week");
			const to = moment(date)?.endOf("week");
			return `${from.format("MMMM DD")} to ${to.format("MMMM DD")}`;
		}
		if (view === Views.MONTH) {
			return moment(date).format("MMMM YYYY");
		}
	}, [view, date]);

	const onTodayClick = useCallback(() => {
		setDate(moment().toDate());
	}, []);

	const clickRef = useRef<any>(null);

	const onSelectEvent = useCallback(
		calEvent => {
			window.clearTimeout(clickRef?.current);

			clickRef.current = window.setTimeout(() => {
				const dateFromCalendar = moment.parseZone(calEvent.start, "ddd MMM DD YYYY HH:mm:ss Z").format("YYYYMMDD");

				let filterEvents = data?.filter(event => {
					let eventDate = moment(event.start).format("YYYYMMDD");
					return eventDate === dateFromCalendar;
				});

				// if (filterEvents.length > 1 && (view === "month" || view === "week")) {
				// 	setDate(moment(calEvent.start).toDate());
				// 	setView("day");
				// } else {
				navigate(`/Events/${calEvent?.id}/${calEvent?.data?.region}`, {
					state: { team: calEvent?.data },
				});

				// }
			}, 250);
		},
		[view],
	);

	const CustomMonthHeader = ({ month }) => {
		const today = new Date();
		let weekDay = DAYS[today.getDay()];
		//
		return (
			<>
				<HStack justifyContent={"space-evenly"}>
					{DAYS.map(day => (
						<Stack flex={1} key={day} alignItems={"center"} p={2} borderWidth={0.5} borderColor={"rgb(238, 238, 238)"}>
							<Text fontWeight={"bold"} color={weekDay === day ? "AVColor.secondary" : "#000000"}>
								{day}
							</Text>
						</Stack>
					))}
				</HStack>
			</>
		);
	};

	const CustomWeekDayHeader = ({ date }) => {
		if (view === "week" || view === "day") {
			const datesArray = date?.dateRange.map(day => {
				return {
					day: day.$d,
					date: day?.$D,
					week: DAYS[day?.$W],
					activeDate: date.activeDate,
				};
			});

			return (
				<HStack alignItems="center" justifyContent={"center"} ml={12} mb={"15px"} mt={4} height={"20px"}>
					{datesArray.map(d => {
						return (
							<Stack
								key={d?.date}
								flex={1}
								alignItems={view === "day" ? "center" : "flex-start"}
								px={2}
								pb={2}
								borderWidth={0.5}
								borderColor={"rgb(238, 238, 238)"}
							>

								<TouchableOpacity onPress={() => onCellPress(d?.day)}>
									<Text
										fontSize={"lg"}
										fontWeight={"bold"}
										color={
											moment().format("YYYYMMDD") === moment(d.day).format("YYYYMMDD")
												? "AVColor.secondary"
												: "AVColor.black"
										}>
										{d?.date}
									</Text>
									<Text
										mt={-1}
										fontSize={"xs"}
										fontWeight={"bold"}
										color={
											moment().format("YYYYMMDD") === moment(d.day).format("YYYYMMDD")
												? "AVColor.secondary"
												: "AVColor.black"
										}>
										{d?.week}
									</Text>
								</TouchableOpacity>
							</Stack>

						);
					})
					}
				</HStack >
			);
		}

		return <></>;
	};

	interface CustomEventType extends ICalendarEventBase {
		color?: string;
		eventType?: string;
		description?: string;
		status?: boolean;
		statusText?: string
	}


	const EventInfo = ({ event }) => {
		return (<VStack bgColor={"white"}>
			{event?.status && <Text bold> {event?.statusText}</Text>}
			<Text color={"black"} fontSize={"lg"} bold px={1} textTransform={"capitalize"}>
				{event.title}
			</Text>
			<Text color={"black"} fontSize={"12px"} bold px={1} textTransform={"capitalize"}>
				{moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}
			</Text>
			<Text color={"black"} fontSize={"12px"} px={1}>
				{event?.description}
			</Text>

		</VStack>)
	}

	const customEventRenderer: EventRenderer<CustomEventType> = (event, touchableOpacityProps) => {
		const color =
			event?.eventType === "Game"
				? "#007DBD"
				: event?.eventType === "Social"
					? "#32AE78"
					: event?.eventType === "Practice"
						? "#C4B70A"
						: "#FA6400";



		return (
			<>
				<Tooltip arrow title={<EventInfo event={event} />}>
					<TouchableOpacity
						{...touchableOpacityProps}
						style={[
							...(touchableOpacityProps.style as RecursiveArray<ViewStyle>),
							{
								backgroundColor: event?.status ? "#ed5249" : color,
								padding: "1px 0px !important",
								margin: '2px 0px',
								borderTopLeftRadius: 5,
								borderBottomLeftRadius: 5,
								borderColor: "black",
								// borderWidth: 5,
								borderLeftWidth: 6,
								overflow: 'hidden',
								borderRightWidth: 1,
								width: view === 'day' ? 100 : 100,
								// maxWidth: view === 'day' ? '80px !important' : 80,
								minWidth: view === 'day' ? 100 : 100,

							},
						]}
						onPress={() => onSelectEvent(event)}
					>


						<>
							{event?.status && <Text bold> {event?.statusText}</Text>}
							{view !== 'month' && <Text color={"white"} fontSize={"12px"} numberOfLines={1} px={1} textTransform={"capitalize"}>
								{moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}
							</Text>}
							<Text color={"white"} fontSize={"xs"} numberOfLines={1} px={1} textTransform={"capitalize"}>
								{event.title}
							</Text>
							{
								view === 'day' &&
								<Text color={"white"} fontSize={"10px"} px={1} noOfLines={1}>
									{event?.description}
								</Text>
							}
						</>

					</TouchableOpacity>
				</Tooltip>
			</>
		);
	};

	const cellStyles = date => {

		const now = moment().format("YYYYMMDD");
		const d = moment(date).format("YYYYMMDD");

		let cellStyles = { height: view === "month" ? 110 : 50 };
		if (now === d && view == "month") {
			cellStyles = {
				...cellStyles,
				// @ts-ignore
				backgroundColor: "#e1e1e1",

			};
		}

		return cellStyles;
	};

	const renderCustomDate = (_date: Date) => {
		return (
			<Stack p={0} m={0} pl={1}>
				<Text fontWeight={"semibold"} fontSize="xs">
					{moment(_date).format("DD")}
				</Text>
			</Stack>
		);
	};

	const onCellPress = date => {

		setView("day");
		setDate(moment(date).toDate());
	};

	const overlapOffset = () => {
		return view === "day" ? 120 : 10;
	}

	return (
		<>
			<Stack width={"100%"} space={1}>
				<HStack marginBottom={1} alignItems={"center"} justifyContent={"space-between"} px={2}>
					<HStack space={2} alignItems={"center"}>
						<ArrowBackIosIcon cursor={"pointer"} onClick={onPrevClick} />
						<Text bold color={"AVColor.secondary"}>
							{dateText?.toUpperCase()}
						</Text>
						<ArrowForwardIosIcon cursor={"pointer"} onClick={onNextClick} />
					</HStack>
					<HStack space={4} alignItems={"center"}>
						<Button variant={"outline"} p={1} m={0} onPress={onTodayClick}>
							<Text bold color={"AVColor.primary"} textTransform={"uppercase"}>
								{TODAY}
							</Text>
						</Button>

						{VIEW_OPTIONS.map(({ id, label }) => {
							return (
								<Box key={id}>
									<Text onPress={() => setView(id)} bold color={view === id ? "AVColor.secondary" : "#000"}>
										{label?.toUpperCase()}
									</Text>
								</Box>
							);
						})}
					</HStack>
				</HStack>

				<NativeCalendar
					events={data?.length > 0 ? eventsData : []}
					height={view === "month" ? 500 : 600}
					date={date}
					// @ts-ignore
					mode={view}
					// maxVisibleEventCount={0}

					showTime={true}
					// minHour={0}
					// maxHour={23}
					ampm={false}
					activeDate={date}
					// showAllDayEventCell={false}
					hourStyle={{
						display: "flex",
						fontSize: 12,
						fontWeight: "bold",
						justifyContent: "center",
						alignItems: "center",
						// margin: "10px 0px 0px",
					}}
					renderHeaderForMonthView={date => <CustomMonthHeader month={date} />}
					showVerticalScrollIndicator={false}
					allDayEventCellStyle={{ backgroundColor: "red" }}
					onPressEvent={event => onSelectEvent(event)}
					renderHeader={date => <CustomWeekDayHeader date={date} />}
					renderEvent={customEventRenderer}
					overlapOffset={view === "day" ? 110 : 10}
					scrollOffsetMinutes={360}
					calendarCellStyle={cellStyles}
					renderCustomDateForMonth={renderCustomDate}
					onPressCell={onCellPress}
					disableMonthEventCellPress={false}
					// hourRowHeight={150}
					showAdjacentMonths={false}
					swipeEnabled={false}
					hideNowIndicator={true}
					// onPressDateHeader={(date) => console.log("date-------", date)}
					// moreLabel="..."
					eventsAreSorted={true}
				/>
			</Stack>
		</>
	);
};
