import React, { FC, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";

interface Props {
	onChange: any;
	type: string;
	label: string;
	defaultValue?: string | undefined;
	width?: any | undefined;
	value: string;
	height?: any | undefined;
	minRows?: any | undefined;
	multiline?: boolean | undefined;
	maxLength?: any | undefined;
	size?: any | undefined;
	placeholder?: string | undefined;
	autoFocus?: boolean;
	onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
	inputRef?: any | null;
}

const AVInputtext: FC<Props> = ({
	onChange,
	type,
	label,
	defaultValue,
	width,
	value,
	height,
	minRows,
	multiline,
	maxLength,
	size,
	placeholder,
	inputRef,
	onKeyPress, // Add this line
}) => {
	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (!multiline) return;

		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault(); // Prevent default behavior (new line)
			if (onKeyPress) {
				// Check if the onKeyPress prop is provided
				onKeyPress(event); // Call the onKeyPress function passed as prop
			}
		}
	};

	return (
		<TextField
			placeholder={placeholder}
			type={type}
			autoComplete="off"
			inputProps={{
				style: {
					height: height,
					width: width,
				},
				maxLength: maxLength,
			}}
			label={label}
			value={value}
			defaultValue={defaultValue}
			multiline={multiline}
			minRows={minRows}
			onChange={onChange}
			size={size ? size : ""}
			inputRef={inputRef}
			onKeyPress={handleKeyPress} // Attach handleKeyPress function here
		/>
	);
};

export default AVInputtext;
