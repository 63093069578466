/*import { SET_USER_DATA } from '../Constants';*/
// import {user} from '../../MockData/sampleData'
const initialState = {
	user: [],
	devicefcmtoken: [],
	userProfile: [],
	userisguardian: true,
	userLang: [],
	userLocation: [],
	userSearch: [],
	entityToken: [],
	entitydatalist: [],
	followersdatalist: [],
	feedsload: false,
	toastShow: true,
	feedsShowing: true,
	onlineState: true,
	followRequestData: [],
	notificationEnabled: true,
	isConvoScreenOpen: [{ isOpen: false, convoId: undefined }],
	lastUpdateFeed: [],
	backgroundMessage: [],
};

const userReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case "SET_USER_DATA":
			return { ...state, user: [action.payload] };
		case "SET_DEVICEFCM_TOKEN_DATA":
			return { ...state, devicefcmtoken: action.payload };
		case "SET_IS_NOTIFICATION_ENABLED":
			return { ...state, notificationEnabled: action.payload };
		case "SET_USERS_PROFILE_DATA":
			return { ...state, userProfile: action.payload };
		case "SET_USERS_GUARDIAN":
			return { ...state, userisguardian: action.payload };
		case "SET_LOGIN_LINK":
			return { ...state, loginLink: action.payload };
		case "SET_USERS_LANG":
			return { ...state, userLang: action.payload };
		case "SET_LOCATION":
			return { ...state, userLocation: action.payload };
		case "SET_SEARCH":
			return { ...state, userSearch: action.payload };
		case "SET_ENTITY_TOKEN":
			return { ...state, entityToken: action.payload };
		case "SET_ENTITY_DATA":
			return { ...state, entitydatalist: action.payload };
		case "SET_FOLLOWERS_DATA":
			return { ...state, followersdatalist: action.payload };
		case "SET_FEEDS_LOAD":
			return { ...state, feedsload: action.payload };
		case "SET_TOAST":
			return { ...state, toastShow: action.payload };
		case "SET_FEEDSSHOWING":
			return { ...state, feedsShowing: action.payload };
		case "SET_ACTIVE_STATE":
			return { ...state, active: action.payload };
		case "SET_FOLLOW_REQUEST_DATA":
			return { ...state, followRequestData: action.payload };
		case "SET_IS_CONVO_SCREEN_OPEN":
			return { ...state, isConvoScreenOpen: action.payload };
		case "SET_LAST_GET_FEED":
			return { ...state, lastUpdateFeed: action.payload };
		case "SET_BACKGROUND_MESSAGE":
			return { ...state, backgroundMessage: action.payload };
		case "SET_USERRESET_STATE":
			return initialState;
		default:
			return state;
	}
};

export default userReducer;
