import {
	AddIcon,
	Box,
	Divider,
	HStack,
	Pressable,
	ScrollView,
	Text,
	VStack,
	CloseIcon,
	Button,
	Image,
	Input,
} from "native-base";
import React, { FC, useRef } from "react";
import { AVButtonRoundedFull, AVButtonRoundInvert } from "components/common/AVButtons";
import AVCancel from "components/common/AVCancel";
import AVEmoji from "components/common/AVEmoji";
import { StyleSheet } from "react-native";
import Picker from "emoji-picker-react";
import * as AV from "AVCore/AVCore";
import { AddLinkIcon, AddPhotoIcon, VideoIcon } from "assets/icons/icon-assets";
import { useWindowDimension } from "Utility/WindowDimensions";
import { Loc } from "App/Loc";
import { Media, Post, Person } from "../../AVCore/AVCore";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setFeeds } from "../../Redux/Actions/feedsAction";
import Dropdown from "../../assets/images/dropdown.svg";
import { v4 as uuidv4 } from "uuid";
import AVEmojiPicker from "../../components/common/AVEmojiPicker";
import { setisActive } from "../../Redux/Actions/userActions";
import { LineIcon } from "react-share";
import {
	extractUrlsFromText,
	extractYouTubeVideoId,
	getContentTypeFromUrl,
	isHuddleURL,
	isYouTubeURL,
} from "./preview/utils/util";

interface Props {
	setShowCreatePost: any;
	Postdata: any;
	type: string;
}

const AVPostPopup : FC<Props> = ({ setShowCreatePost, Postdata, type }) => {
  const dispatch = useDispatch();
  const dimensions = useWindowDimension();
  const textRef = React.useRef<any>(null);
  const imageInputRef = React.useRef<HTMLInputElement>(null);
  const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);
  const userfeeds = useSelector((state : RootStateOrAny) => state.feeds.feeds[ 0 ]);
  const [ showAddMedia, setShowAddMedia ] = React.useState(false);
  const [ images, setImages ] = React.useState<any>([]);
  const [ videos, setvideos ] = React.useState<any>([]);
  const [ media, setMedia ] = React.useState<any>([]);
  const [ Img, setImg ] = React.useState<any>([]);
  const [ video, setvio ] = React.useState<any>([]);
  const [ picker, setPicker ] = React.useState(false);
  const [ text, setText ] = React.useState('');
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ isImageProcessing, setIsImageProcessing ] = React.useState(false);
  const [ alertMessage, setAlertMessage ] = React.useState('');
	const [ successMessage, setSuccessMessage ] = React.useState('');
	const [ showExternalLinkInput, setShowExternalLinkInput ] = React.useState(false);
	const [ externalLink, setExternalLink ] = React.useState("");
    const [ visibility, setvisibility ] = React.useState(
        ( Postdata?.id == "78b5b063-fd3e-4925-92bd-2a1888c6a46a" || Postdata?.parent?.id == "78b5b063-fd3e-4925-92bd-2a1888c6a46a" ) ? "Public" :
            ( Postdata?.type == "School" || Postdata?.subType == "Club" || Postdata?.type == "Team" ) ? Postdata?.preferences?.defaultPostVisibility ? Postdata?.preferences?.defaultPostVisibility  : Loc.currentLang.menu.follow : ( Postdata?.subType == "Class" || Postdata?.subType == "GradClass" || Postdata?.type == "Group" || Postdata?.type == "Organization" || Postdata?.type == "Neighborhood" ) ? Postdata?.preferences?.defaultPostVisibility ? Postdata?.preferences?.defaultPostVisibility :Loc.currentLang.menu.private :
                Postdata?.preferences?.defaultPostVisibility ? Postdata?.preferences?.defaultPostVisibility : currentUser?.preferences?.defaultPostVisibility == "Follow" ? "Followers" : currentUser?.preferences?.defaultPostVisibility );
  const [ isActive, setIsActive ] = React.useState( false );
  const isMobile = dimensions?.width <= 500 || false;
  const newGuid = uuidv4();
  const handleDrop = async (e) => {
    e.preventDefault();

		const doc = e.dataTransfer.files;
		const fileInput: any = imageInputRef.current;
		let files: any = Array.from(doc);
		const updatedFiles = files.map((file: any) => {
			const fileName = file.name;
			if (fileName.toLowerCase().endsWith(".jfif")) {
				const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
				const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
				return updatedFile;
			} else {
				return file;
			}
		});
		files = updatedFiles;
		let m = "";
		console.log(files);
		if (files.length > 20) {
			m = Loc.currentLang?.alertMessages?.maxUploadFiles || "You cannot upload more than 20 files per post";
			setAlertMessage(m);
			return;
		}
		setShowAddMedia(false);
		setPicker(false);
		files.forEach((img: any) => {
			if (
				img.type !== "image/jpeg" &&
				img.type !== "image/jfif" &&
				img.type !== "image/png" &&
				img.type !== "image/webp" &&
				img.type !== "image/gif" &&
				img.type !== "video/mp4" &&
				img.type !== "video/quicktime"
			) {
				m =
					Loc.currentLang.alertMessages.supportedFormats ||
					"format is unsupported ! only Jpeg,Jfif, Png, Webp, Gif are allowed.";
				files = files.filter((item: any) => item.name !== img.name);
				return;
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(img);
				reader.onload = readerEvent => {
					if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target?.result]);
					else setvideos(videos => [...videos, readerEvent.target?.result]);
				};
			}
		});
		setIsImageProcessing(true);

		const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
		if (data?.length > 0) {
			setMedia(prev => [...prev, ...data]);
			data?.forEach((img: any) => {
				if (img?.type?.startsWith("image")) setImg(images => [...images, img]);
				else setvio(videos => [...videos, img]);
			});
		}
		fileInput.value = null;
		setIsImageProcessing(false);
	};

	const handleDragOver = e => {
		e.preventDefault();
	};

	const handleExternalLink = e => {
		setExternalLink(e.target.value);
	};
	const handleImages = async (e: any) => {
		setAlertMessage("");
		const fileInput: any = imageInputRef.current;

		let files: any = Array.from(e?.target?.files);
		const updatedFiles = files.map((file: any) => {
			const fileName = file.name;
			if (fileName.toLowerCase().endsWith(".jfif")) {
				const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
				const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
				return updatedFile;
			} else {
				return file;
			}
		});
		files = updatedFiles;
		let m = "";

		if (files.length > 20) {
			m = Loc.currentLang?.alertMessages?.maxUploadFiles || "You cannot upload more than 20 files per post";
			setAlertMessage(m);
			return;
		}
		setShowAddMedia(false);
		setPicker(false);
		files.forEach((img: any) => {
			if (
				img.type !== "image/jpeg" &&
				img.type !== "image/jfif" &&
				img.type !== "image/png" &&
				img.type !== "image/webp" &&
				img.type !== "image/gif" &&
				img.type !== "video/mp4" &&
				img.type !== "video/quicktime"
			) {
				m =
					Loc.currentLang.alertMessages.supportedFormats ||
					"format is unsupported ! only Jpeg,Jfif, Png, Webp, Gif are allowed.";
				files = files.filter((item: any) => item.name !== img.name);
				return;
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(img);
				reader.onload = readerEvent => {
					if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target?.result]);
					else setvideos(videos => [...videos, readerEvent.target?.result]);
				};
			}
		});
		setIsImageProcessing(true);
		console.log(files);
		const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
		if (data?.length > 0) {
			setMedia(prev => [...prev, ...data]);
			if (data?.length > 0) {
				data.forEach(img => {
					if (img?.type?.startsWith("image")) setImg(images => [...images, img]);
					else setvio(videos => [...videos, img]);
				});
			}
		}
		fileInput.value = null;
		setIsImageProcessing(false);
	};

	const handlePublish = async () => {
		const text = textRef?.current.value || null;
		if (text === null && media.length === 0) {
			setAlertMessage(Loc.currentLang.menu.emptyMediaErrorMsg);
			return;
		}
		if (media.length > 20) {
			setAlertMessage(Loc.currentLang.menu.limitExccedErrorMsg);
			return;
		}
		setAlertMessage("");
		setIsLoading(true);
		let param: AV.IFeedParam = {
			startDate: new Date(0),
			forward: true,
			checkArchive: false,
			feed: AV.AVBase.getFk(Postdata ? Postdata : Person.self()),
		};
		if (param?.feed?.id) {
			let post: any = { pk: param?.feed?.id, region: param?.feed?.region };
			post.text = text;
			post.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
			if (media && media.length === 1) {
				post.media = media[0];
			}
			if (media && media.length > 1) {
				let galleryPost: any[] = [];
				let gallery = { layout: null, Items: [] };
				post.gallery = gallery;
				post.id = newGuid;
				let parentPostId = newGuid;
				let galleryArray: string[] = [];
				for (var i = 0; i < media.length; i++) {
					let param1: AV.IFeedParam = {
						feed: AV.AVBase.getFk(Postdata ? Postdata : Person.self()),
						startDate: new Date(0),
						forward: true,
						checkArchive: false,
					};
					let post1: any = { pk: param1?.feed?.id, region: param1?.feed?.region };
					post1.parentPost = parentPostId;
					post1.media = media[i];
					post1.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
					post1.ptype = "G";
					let childresponse: any = await Post.save(post1);
					dispatch(setisActive(true));
					if (childresponse === "Inappropriate Content") {
						setAlertMessage(Loc.currentLang.menu.inappropriateErrorMsg);
						setIsLoading(false);
						return;
					}
					galleryArray.push(childresponse.id);
					galleryPost.push(childresponse);
				}
				post.status = "New";
				post.gallery.items = galleryArray;
				let val: any = await Post.save(post);
				dispatch(setisActive(true));
				if (val === "Inappropriate Content") {
					setAlertMessage(Loc.currentLang.menu.inappropriateErrorMsg);
					setIsLoading(false);
					return;
				}
				val.galleryPost = galleryPost;
				userfeeds.unshift(val);
				dispatch(setFeeds(userfeeds));
				setMedia([]);
				setIsLoading(false);
				setShowCreatePost(false);
				return;
			}
			if (externalLink) {
				let newLink = externalLink;
				let getExternalLinkType = "application/octet-stream";
				const youtubeUrl = isYouTubeURL(newLink);
				const huddleUrl = isHuddleURL(newLink);

				if (youtubeUrl) {
					newLink = youtubeUrl;
				} else if (huddleUrl) {
					newLink = huddleUrl;
				} else {
					getExternalLinkType = getContentTypeFromUrl(newLink);
					console.log(getExternalLinkType);
				}
				if (newLink) {
					let fd = new FormData();
					fd.append("linkURL", newLink);
					const data = await AV.Media.saveFiles(fd, currentUser?.region);
					data[0].linkURL = newLink;
					data[0].orgFile = "";
					data[0].type = getExternalLinkType;
					post.media = data[0];
				}
			}

			let val: any = await AV.Post.save(post);
			dispatch(setisActive(true));
			if (val === "Inappropriate Content") {
				setAlertMessage(Loc.currentLang.menu.inappropriateErrorMsg);
				setIsLoading(false);
				return;
			}
			userfeeds.unshift(val);
			dispatch(setFeeds(userfeeds));
			setMedia([]);
			setIsLoading(false);
			setSuccessMessage(Loc.currentLang.menu.postPublishSuccessMsg);
			setShowCreatePost(false);
		}
	};

	const menuRefs = useRef(null);
	useOutsideAlerter(menuRefs);
	function useOutsideAlerter(ref) {
		React.useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsActive(false);
					setPicker(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const handleEmoji = (e, { emoji }) => {
		const ref: any = textRef.current;
		ref.focus();
		const start = text.substring(0, ref.selectionStart);
		const end = text.substring(ref.selectionStart);
		const newText = start + emoji + end;
		setText(newText);
		setPicker(!picker);
	};
	const handeEmojiPicker = e => {
		const ref: any = textRef.current;
		ref.focus();
		const start = text?.substring(0, ref.selectionStart);
		const end = text?.substring(ref.selectionStart);
		const newText = start + e?.native + end;
		setText(newText);
		setPicker(!picker);
	};

	const handletest = () => {
		setPicker(false);
	};
	const mobile = dimensions?.width < 600 || false;

	return (
		<Box position="fixed" bgColor={"AVColor.overlay2"} bottom={0} right={0} top={0} left={0}>
			<div
				className={`create_post_popup ${mobile && "create_post_popup_mobile"}`}
				style={{
					width: mobile ? dimensions.width - 15 : "500px",
					maxHeight: mobile ? "650px" : "700px",
					marginTop: "12px",
				}}>
				{picker && !mobile && (
					<Box
						position={"absolute"}
						ref={menuRefs}
						zIndex={99}
						borderColor={"#2FA3BE"}
						borderStyle={"solid"}
						borderWidth={"5px"}
						width={mobile ? "85%" : "88%%"}>
						<AVEmojiPicker
							onEmojiSelect={e => {
								return handeEmojiPicker(e);
							}}
						/>
					</Box>
				)}
				{picker && mobile && (
					<Box
						position={"fixed"}
						top={0}
						right={0}
						ref={menuRefs}
						zIndex={99}
						borderColor={"#2FA3BE"}
						borderStyle={"solid"}
						borderWidth={"5px"}
						width={"85%"}>
						<AVEmojiPicker
							onEmojiSelect={e => {
								return handeEmojiPicker(e);
							}}
						/>
					</Box>
				)}

				<VStack>
					<HStack
						alignItems={"center"}
						justifyContent={"space-between"}
						px={5}
						marginTop={"20px"}
						borderBottomColor={"AVColor.grey"}>
						<Text fontSize={24}>{Loc.currentLang.post.createPost}</Text>
						<AVCancel callback={setShowCreatePost} color={undefined} size={undefined} />
					</HStack>

          <ScrollView showsHorizontalScrollIndicator={ false } maxHeight={ mobile ? '300px' : '400px' }>
            <HStack marginLeft={ '24px' } marginTop={ '9px' } space={ 3 }>
              <Text fontSize={ '18' } marginTop={ '2px' }>{ Loc.currentLang.menu.whoCanView }:</Text>
              <HStack
                ref={ menuRefs }
              >
                <HStack >
                  <Button
                    width={ '160px' }
                    bgColor={ '#DAF2F4' }
                    rounded='full'
                    fontWeight='550px'
                    color='#fff'
                    p={ 2 }
                    justifyContent={ 'flex-start' }
                    minHeight='35px'
                    borderRadius={ '20px' }
                    borderColor={ 'AVColor.primary' }
                    onPress={ () => {
                      setIsActive(!isActive)
                    } }
                  >
                    <Text color={ '#2FA3BE' } lineHeight={ '1rem' } paddingLeft={ '18px' } >{ visibility }</Text>
                  </Button>
                  <Pressable position={ 'absolute' } right={ 0 } top={ 2 } onPress={ () => {
                    setIsActive(!isActive)
                  } } >
                    <Image source={ Dropdown } alt='Actavivo' size='25px' />
                  </Pressable>
                </HStack>
                <HStack>
                  { isActive && (<HStack position={ 'absolute' } right={ 0 } top={ '39px' } flexDirection={ 'column' } width={ '156px' } borderRadius={ '5px' } bgColor='#FFFFFF' shadow='9' rounded='lg' zIndex={ 100 } >
                    <Button onPress={ () => { setvisibility(Loc.currentLang.menu.private); setIsActive(!isActive); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '156px' }  ><Text color={ 'black' } width={ '130px' } text-overflow={ 'ellipsis' }
                      numberOfLines={ 1 }>{ Loc.currentLang.menu.private }</Text></Button>
                    <Button onPress={ () => { setvisibility(Loc.currentLang.menu.public); setIsActive(!isActive); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '156px' }><Text color={ 'black' } width={ '130px' } text-overflow={ 'ellipsis' }
                      numberOfLines={ 1 }>{ Loc.currentLang.menu.public }</Text></Button>
                                      <Button onPress={ () => { setvisibility( Loc.currentLang.menu.followers ); setIsActive(!isActive); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '156px' }><Text color={ 'black' } width={ '130px' } text-overflow={ 'ellipsis' }
                                          numberOfLines={ 1 } >{ Loc.currentLang.menu.followers }</Text></Button>
                  </HStack>
                  ) }
                </HStack>
              </HStack>
            </HStack>
            <div>
              <Box
                my={ 5 }
                mx={ 10 }
                borderWidth={ 1 }
                borderRadius={ 5 }
                overflow={ 'hidden' }
                borderColor={ 'AVColor.grey' }
                zIndex={ isActive ? '-1' : '0' }
              >
                              <textarea
                                  ref={ textRef }
                                  value={ text }
                                  style={ {
                                      border: 'none',
                                      resize: 'none',
                                      height: mobile ? '20px' : '75px',
                                      padding: '20px 15px',
                                      outline: 'none',
                                      fontFamily: 'inherit',
                                      fontSize: 'inherit',
                                  } }
                                  placeholder={
                                      Loc.currentLang.post?.createPostPlaceholder || 'Share what is on your mind!'
                                  }
                                  onChange={ ( e ) =>
                                  {
                                      setText( e.target.value );
                                      setAlertMessage( '' );
                                  } }
                                  onKeyDown={ ( e ) =>
                                  {
                                      if ( e.key === 'Enter' && !e.shiftKey && !isMobile &&  !isLoading && !isImageProcessing )
                                      {
                                          e.preventDefault();
                                          handlePublish();
                                      }
                                  } }
                                  autoFocus
                              ></textarea>
                <Text textAlign={ 'right' }>
                  <AVEmoji showEmoji={ () => setPicker((prev) => !prev) } />
                </Text>
              </Box>
              { ((images && images.length > 0) || (videos && videos.length > 0)) && (
                <Pressable
                  mx={ 5 }
                  onPress={ () => imageInputRef?.current?.click() }
                  _hover={ { bgColor: 'AVColor.hovercolor2', borderRadius: 'md' } }
                  p={ 1 }
                  px={ 1 }
                  width={ '250px' }
                  disabled={ isImageProcessing }
                >
                  <div
                    className="image-drop-zone"
                    onDrop={ handleDrop }
                    onDragOver={ handleDragOver }
                                  >
                    <HStack alignItems={ 'center' } space={ 3 } >
                      <HStack bgColor={ 'AVColor.iconbg' } padding={ '4px' } borderRadius={ 'full' }>
                        <AddPhotoIcon size={ 'md' } color={ 'AVColor.primaryText' } />
                      </HStack>
                      <Text variant={ 'AVSubheader' } color={ 'AVColor.secondaryTextColor' }>
                        { Loc.currentLang.post.addMorePhotosVideos || 'Add more photos/videos' }
                      </Text>
                    </HStack>
                  </div>
                </Pressable>
              ) }
              <input
                type='file'
                accept='image/jpeg,image/jfif,image/png,image/webp,image/gif,video/mp4,'
                multiple
                hidden
                ref={ imageInputRef }
                onChange={ handleImages }
              />

							{showAddMedia && (
								<>
									<Pressable
										marginLeft={"44px"}
										mx={5}
										borderWidth={1}
										borderRadius={5}
										overflow={"hidden"}
										borderColor={"avcolors.black"}
										borderStyle={"dashed"}
										maxH={"300px"}
										width={"82%"}
										zIndex={0}
										_hover={{ bgColor: "AVColor.hovercolor2" }}
										onPress={() => imageInputRef?.current?.click()}>
										<div className="image-drop-zone" onDrop={handleDrop} onDragOver={handleDragOver}>
											<VStack alignItems={"center"} justifyContent={"center"} minH={"150px"} space={2}>
												<Box p={2} borderRadius="full" bgColor={"AVColor.iconbg"} shadow={1}>
													<AddIcon color={"AVColor.primaryText"} />
												</Box>
												<Text fontSize={14} color={"AVColor.secondaryText"}>
													( {Loc.currentLang.post.addPhotoVideo} )
												</Text>
											</VStack>
										</div>
									</Pressable>
									<HStack position={"absolute"} right={"10px"} bottom={"37%"}>
										<AVCancel callback={() => setShowAddMedia(prev => !prev)} color={undefined} size={undefined} />
									</HStack>
								</>
							)}

							<HStack my={3} mx={5} space={4} alignItems={"center"} zIndex={0}>
								{(images && images.length) || (videos && videos.length) ? (
									<RenderImages
										setImg={setImg}
										Img={Img}
										setvio={setvio}
										video={video}
										images={images}
										setImages={setImages}
										setvideos={setvideos}
										videos={videos}
										setMedia={setMedia}
										media={media}
									/>
								) : (
									!showAddMedia && (
										<Pressable
											onPress={() => {
												handletest();
												setShowAddMedia(prev => !prev);
											}}
											_hover={{ bgColor: "AVColor.hovercolor2", borderRadius: "md" }}
											p={1}
											px={1.5}>
											<HStack alignItems={"center"} space={3}>
												<HStack bgColor={"AVColor.iconbg"} padding={"4px"} borderRadius={"full"}>
													<AddPhotoIcon size={"24px"} color={"black"} />
												</HStack>
												<Text variant={"AVSubheader"} color={"AVColor.secondaryTextColor"}>
													{Loc.currentLang.post.addToYourPost}
												</Text>
											</HStack>
										</Pressable>
									)
								)}
							</HStack>
							<HStack my={3} mx={5} space={4} alignItems={"center"} zIndex={0}>
								<Pressable
									onPress={() => {
										handletest();
										setShowAddMedia(false);
										setShowExternalLinkInput(prev => !prev);
									}}
									_hover={{ bgColor: "AVColor.hovercolor2", borderRadius: "md" }}
									p={1}
									px={1.5}>
									<HStack alignItems={"center"} space={3}>
										<HStack bgColor={"AVColor.iconbg"} padding={"4px"} borderRadius={"full"}>
											<AddLinkIcon size={"24px"} color={"AVColor.primary"} />
										</HStack>
										<Text variant={"AVSubheader"} color={"AVColor.secondaryTextColor"}>
											{/* {Loc.currentLang.post.addToYourPost}
											 */}
											Add link to your post
										</Text>
									</HStack>
								</Pressable>
							</HStack>
						</div>
					</ScrollView>
					{(alertMessage || successMessage) && (
						<HStack alignItems={"center"} justifyContent={"center"}>
							<Text fontSize={16} color={alertMessage ? "danger.600" : "success.600"}>
								{alertMessage || successMessage}
							</Text>
						</HStack>
					)}
					<Divider my={3} px={3} />
					<HStack justifyContent={"center"} pr={3} mb={4} space={3}>
						<>
							<AVButtonRoundInvert
								btnText={Loc.currentLang.menu.cancel}
								callback={() => setShowCreatePost(false)}
								px={undefined}
								height={undefined}
								isLoadingText={undefined}
								isDisabled={undefined}
								isLoading={undefined}
								loading={undefined}
							/>
							<AVButtonRoundedFull
								btnText={Loc.currentLang.menu.publish}
								callback={handlePublish}
								isDisabled={text?.trim()?.length === 0 && media?.length === 0}
								loading={isLoading || isImageProcessing}
								isLoadingText={isImageProcessing ? Loc.currentLang.post.processing : Loc.currentLang.post.publishing}
								px={undefined}
								height={undefined}
								isLoading={undefined}
							/>
						</>
					</HStack>
				</VStack>
			</div>
		</Box>
	);
};
const RenderImages = ({ images, setImages, videos, setvideos, setMedia, media, setImg, Img, setvio, video }) => {
	const Grid2styles = StyleSheet.create({
		container: { flex: 1, flexDirection: "row", flexWrap: "wrap", minwidth: "120px" },
		img1: {
			backgroundColor: "lightblue",
			borderColor: "#fff",
			borderWidth: 1,
			width: "230px",
			height: "auto",
		},
	});
	const handlecancelimage = img => {
		setMedia(media.filter(f => f.id !== img.id));
		setImages(images.filter(f => f.id !== img.id));
		setvideos(videos.filter(f => f.id !== img.id));
		setImg(Img?.filter(f => f.id !== img.id));
		setvio(video?.filter(f => f.id !== img.id));
	};

	return (
		<>
			<div className="add_pics_inside1 p0">
				{(Img.length > 0 || video.length > 0) && (
					<HStack alignContent={"center"} alignSelf={"center"} justifyContent={"center"} space={3}>
						{(Img.length > 0 || video.length > 0) && (
							<Box style={Grid2styles.container}>
								{Img.length > 0 &&
									Img?.slice(0, 20).map((media, i) => (
										<HStack style={Grid2styles["img1"]} key={media.id + i}>
											<Pressable
												position={"absolute"}
												background={"#dcdc"}
												borderRadius={"50px"}
												zIndex={4}
												padding={2}
												right={0}
												onPress={() => {
													handlecancelimage(media);
												}}>
												<CloseIcon color={"#fff"} size={5} />
											</Pressable>
											<img width={"230px"} height={230} src={Media.getURL(media)} key={media.id + i} alt="" />
										</HStack>
									))}

								{video.slice(0, 20).map((vid, i) => (
									<Box key={i}>
										<HStack style={Grid2styles["img1"]} key={vid + i}>
											<Pressable
												position={"absolute"}
												background={"#dcdc"}
												borderRadius={"50px"}
												zIndex={4}
												padding={2}
												right={0}
												onPress={() => {
													handlecancelimage(vid);
												}}>
												<CloseIcon color={"#fff"} size={5} />
											</Pressable>
										</HStack>
										<HStack position={"absolute"} top={"35%"} left={"35%"}>
											<VideoIcon size={"60px"} color="white" />
										</HStack>

										<img width={"230px"} height={230} src={Media.getVideoPicURL(vid)} key={vid?.id + i} alt="" />
									</Box>
								))}
							</Box>
						)}
					</HStack>
				)}
			</div>
		</>
	);
};

export default AVPostPopup;
