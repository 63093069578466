import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "./Auth";
import { isMobile as isMobileDevice } from "react-device-detect";

export class AVAuth {

	static avToken: { authToken: string, expires: Date, isComplete: boolean, refreshToken: string, staySignedIn: boolean } | null = null;	
	static waitRedir: boolean = false;
	static waitRefresh: boolean = false;
	static debug: boolean = false;

	public static getBaseURL():string {
		//debug
		if (this.debug)
			return "https://localhost:7245/";
		if (window.location.hostname == 'dev.actavivo.com' || window.location.hostname == 'localhost')
			return "https://authdev.actavivo.com/";
		if (window.location.hostname == 'qa.actavivo.com')
			return "https://authqa.actavivo.com/";

		
		return "https://auth.actavivo.com/";
	}

	public static async handleRedir () : Promise<boolean>
	{	
		if (AVAuth.waitRedir)
			return false;
		const params = new URLSearchParams(window.location.search);

		var redirTok = params.get("AVTok");

		if ( redirTok )
		{
			if ( isMobileDevice )
			{
				window.location.href = 'actavivo://actavivo.com/AVTok='+redirTok;
			}
			AVAuth.waitRedir = true;
			params.delete("AVTok");
			
			let searchString = params.toString().length > 0 ? '?' + params.toString() : '';
			let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString + window.location.hash;
			window.history.replaceState(null, '', newUrl);
			//Get AuthToken
			var header = {};
			await fetch(AVAuth.getBaseURL() + "api/Auth/AuthToken?token=" + redirTok, {
				method: "GET",
				headers: header,
			})
				.then(
					async function (response) {
						if (response.redirected) {
							console.log("resp: ", "redir")
						} else if (response.status === 401) {							
							//Bad token
							//if (AVAuth.avToken)
							//	await AVAuth.getToken();
						} else if (response.ok) {							
							AVAuth.avToken = await response.json();
							if (AVAuth.avToken?.staySignedIn)
								localStorage.setItem("AVAuth", JSON.stringify(AVAuth.avToken));
							AVAuth.waitRedir = false;
							return true;
						}
					});				
		}
		AVAuth.waitRedir = false;
		return false;
	}
	public static async init() {

		await AVAuth.getToken()
	}

	public static isLoggedIn () : boolean
	{
		if (this.avToken) {
			return true;
		}
		return false;
	}
	public static isAppAuth () : boolean
	{	
		if (this.avToken && this.avToken.isComplete === true) {
			return true;
		}
		return false;
	}

	public static async verifyLogin() {
		this.signIn();		
	}
	
	public static async logout() {		
		localStorage.removeItem("AVAuth");
		AVAuth.avToken = null;

		//Go to logout page, need to see if this iss needed
		//window.location.href = this.getBaseURL() + "identity/account/logout?returnUrl=" + window.location.href;		
		window.location.href = window.location.protocol + "//" + window.location.host +  "/";
	}
	public static async signIn ()
	{
		await AVAuth.handleRedir();
		if (!AVAuth.waitRedir) {
			localStorage.removeItem("AVAuth");
			AVAuth.avToken = null;
			window.location.href = this.getBaseURL() + "identity/account/login?returnUrl=" + window.location.href;
		}
	}
	
	public static async refreshToken ( force : boolean = false )
	{
		const delay = ms => new Promise(res => setTimeout(res, ms));

		//Only do 1 at a time
		if (AVAuth.waitRefresh) {
			var exit = 1;
			while (AVAuth.waitRefresh) {
				await delay(100);
				exit++;
				//Dont spin forever
				if (exit > 20)
					break;
			}
			return;
		}

		AVAuth.waitRefresh = true;
		var header = {};
		header["Accept"] = "application/json";
		header["Content-Type"] = "application/json";
		localStorage.removeItem("AVAuth");
		await fetch(AVAuth.getBaseURL() + "api/Auth/RefreshToken", {
			method: "Post",
			body: JSON.stringify(this.avToken),
			headers: header,
		})
			.then(
				async function (response) {
					if (response.redirected) {
						
					} else if (response.status === 403) {
						
						AVAuth.signIn();
					} else if (response.status === 401) {
						
						AVAuth.signIn();
					} else if (response.ok) {
						AVAuth.avToken = await response.json();
						if (AVAuth.avToken?.staySignedIn)
							localStorage.setItem("AVAuth", JSON.stringify(AVAuth.avToken));
					}
				});
		AVAuth.waitRefresh = false;
	}
	public static async getToken ()
	{
		await AVAuth.handleRedir();

		if (this.avToken != null) {
			if (this.avToken.isComplete !== true || new Date(this.avToken.expires) < new Date()) {
				await AVAuth.refreshToken();				
			}
			return AVAuth.avToken?.authToken;
		}
		else {
			var sTok = localStorage.getItem("AVAuth");
			if (sTok != null) {
				AVAuth.avToken = JSON.parse(sTok);
				if (AVAuth.avToken && (AVAuth.avToken.isComplete !== true || new Date(AVAuth.avToken.expires) < new Date())) 
					await AVAuth.refreshToken();
				if (!AVAuth.avToken)
					AVAuth.signIn();
				return AVAuth.avToken?.authToken;
			}
			
		}
		AVAuth.signIn();
	}
	public static currentUser ()
	{
		if (AVAuth.avToken)
			return { username :"Temp Name TODO"}
		return null;
	}
	public static isValidClaim(): boolean {
		
		return AVAuth.isAppAuth();
	}


}
