import React, { FC, useCallback, useState } from "react";
import { Linking } from "react-native";
import { Text } from "native-base";
import Moment from "moment";

interface Props {
	date: string;
	page: any | undefined;
}

const AVConverttime: FC<Props> = ({ date, page }) => {
	const [currentTime, setCurrentTime] = useState(Moment());

	React.useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(Moment());
		}, 60000);

		return () => clearInterval(intervalId); // Cleanup interval on unmount
	}, []);

	const convertTime = useCallback(
		timestamp => {
			const now = currentTime;
			const targetTime = Moment(timestamp);
			const diff = now.diff(targetTime);
			if (diff >= 365 * 24 * 60 * 60 * 1000) {
				return `${now.diff(targetTime, "years")} Y`;
			} else if (diff >= 30 * 24 * 60 * 60 * 1000) {
				return `${now.diff(targetTime, "months")} M`;
			} else if (diff >= 24 * 60 * 60 * 1000) {
				return `${now.diff(targetTime, "days")} d`;
			} else if (diff >= 60 * 60 * 1000) {
				return `${now.diff(targetTime, "hours")} h`;
			} else {
				return `${now.diff(targetTime, "minutes")} min`;
			}
		},
		[currentTime],
	);
	return page === "Post" ? (
		<>{Moment(date).fromNow()}</>
	) : (
		<Text right={"15px"} fontSize="16px" color={"AVColor.verydarkshadeofgrayColor"} fontWeight="bold">
			{convertTime(date)}
		</Text>
	);
};

export default AVConverttime;
