import React, { useState } from 'react';
import { Input, Text, View, Image, Modal, VStack, HStack, Button, Divider, Box } from 'native-base';
import { Loc } from '../../App/Loc';
import { Person } from '../../AVCore/Person';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setFeeds, setFeedsUpdate } from '../../Redux/Actions/feedsAction';
import { setUser } from '../../Redux/Actions/userActions';
import { AVEntity } from '../../AVCore/AVEntity';
import { Post } from '../../AVCore/Post';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { setisActive, setFollowersData } from '../../Redux/Actions/userActions';
import _ from 'lodash';

export default function AVConfirmationModal(props: any) {
	const [isconfirmLoading, setisconfirmLoading] = React.useState(false);
	const currentUser = useSelector((state: RootStateOrAny) => state?.user?.user[0]);
	const userFeeds = useSelector((state: RootStateOrAny) => state?.feeds.feeds[0]);
	const followersdatalist = useSelector((state: RootStateOrAny) => state?.user?.followersdatalist);
	const dispatch = useDispatch();
	var sourceFK: any = AVEntity.getFk(currentUser);
	const dimensions = useWindowDimension();
	const mobile = dimensions?.width < 600 || false;
	const isMobile = dimensions?.width <= 500 || false;

    const replacedeleteChildrenInParent = ( postId, parentpostId, nestedData, newChildren ) =>
    {
        for ( const item of nestedData )
        {
            if ( item.id === parentpostId )
            {
                if ( !item?.children )
                    item.children = [];
                let val = item.children.filter( x => x?.id !== postId );
                item.children = val;
                item.posts = item.posts.filter( x => x !== postId );
                return item;
            }
            if ( item.children && item.children.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replacedeleteChildrenInParent( postId, parentpostId, item.children, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }
            if ( item?.galleryPost && item.galleryPost.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replacedeleteChildrenInParent( postId, parentpostId, item.galleryPost, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }

        }
        return null; // Parent post not found
    };
    const handlefunction = async (itemFK: any) => {
        setisconfirmLoading(true);
        switch (props?.type) {
            case "UNFollow":
            await Person.unFollow(sourceFK, itemFK)
                if (userFeeds) {
                    let userFeed = userFeeds?.filter( ( x : any ) =>
                    {
                        console.log( 'Owner ID:', x?.owner?.id );
                        console.log( 'Item FK ID:', itemFK?.id );
                        console.log( 'Visibility:', x?.visibility );
                        return x?.owner?.id !== itemFK?.id ;
                    } );
                    let publicPost = userFeeds?.filter( ( x : any ) =>
                    {
                        console.log( 'Owner ID:', x?.owner?.id );
                        console.log( 'Item FK ID:', itemFK?.id );
                        console.log( 'Visibility:', x?.visibility );
                        return x?.owner?.id === itemFK?.id || x?.visibility === 'Public';
                    } );
                   userFeed =  userFeed?.concat( publicPost );
                   userFeed =  userFeed?.sort( ( a : any, b : any ) =>
                    {
                        const createDateA = new Date( a.createDate ) as any;
                        const createDateB = new Date( b.createDate ) as any;
                        return createDateB - createDateA;
                    } );
                    userFeed = _.uniqBy( userFeed, 'id' );
                    //console.log( "userFeed", userFeed )
                    dispatch( setFeeds( userFeed ));
                }
                //await Person.getSelf().then(x => { dispatch(setUser(x)) });
                break;
            case "Follow":
                let targetFK = props.item?.source ? props?.item?.source : props?.item?.target ? props?.item?.target : props?.item;
                targetFK.linkType = 'Follow';
                let resp =
                    props.type === 'Follow'
                        ? await Person.makeRequest(sourceFK, targetFK)
                        : props.type === 'UNFollow'
                            ? await Person.unFollow(sourceFK, targetFK)
                            : '';
                if (props.type === "unfollow") {
                    let userFeed = userFeeds?.filter((x: any) => x?.owner?.id !== targetFK?.id);
                    dispatch(setFeeds(userFeed));
                }
                await Person.get(sourceFK).then(x => {
                    dispatch(setUser(x));
                });
                break;
            case "UNFolloMe":
                const Followersdata = followersdatalist.filter( x => x.id !== props?.item?.id );
                if ( Followersdata )
                {
                    dispatch( setFollowersData( Followersdata ) );
                }
            await Person.unFollowMe(sourceFK, itemFK)
                if (userFeeds) {
                    let userFeed = userFeeds?.filter((x: any) => x?.owner?.id !== itemFK?.id);
                    dispatch(setFeeds(userFeed));
                }
                //props?.setfollowersList(props?.followersList.filter((a: any) => a.id !== itemFK.id));
                await Person.getSelf().then(x => { dispatch(setUser(x)) });
                break;
            case "Block":
               
                let val = await Person.block(sourceFK, props?.item?.source ? props?.item?.source : props?.item?.target ? props?.item?.target : props?.item);
                await Person.getSelf().then(x => { dispatch(setUser(x)) });
                break;
            case "UNBlock":
                await Person.unBlock(sourceFK, props?.item);
                await Person.getSelf().then(x => {
                    dispatch(setUser(x)); if(props?.setblockeddata){props?.setblockeddata(x?.blocked)};
                });
                break;
            case "DeletePost":
                await Post.delete(props?.item).then((x: any) => {
                    props.item.status = 'Deleted';
                    if (userFeeds) {
                        //dispatch( setisActive( true ) );
                        let userfeed = userFeeds?.filter((x: any) => x?.id !== props.item.id);
                        dispatch(setFeeds(userfeed));
                        dispatch(setFeedsUpdate(true));
                    }
                });
                break;
            case "DeleteComment":
                await Post.delete( props?.item );

				let res = userFeeds?.filter(x => x.id === props?.item.id);
				if (res?.length > 0) {
					val[0].status = "Deleted";
					userFeeds[userFeeds.indexOf(val[0])] = val[0];
					dispatch(setFeeds(userFeeds));
					dispatch(setFeedsUpdate(true));
				} else {
					const updatedParentPost = replacedeleteChildrenInParent(
						props?.item?.id,
						props?.item?.parentPost,
						userFeeds,
						props?.item,
					);
					if (updatedParentPost) {
						let exciting = userFeeds?.filter(x => x.id == updatedParentPost?.id);
						userFeeds[userFeeds.indexOf(exciting[0])] = updatedParentPost;
						dispatch(setFeeds(userFeeds));
						dispatch(setFeedsUpdate(true));
					}
				}
				break;
			case "ReportPost":
				await Post.Report(props?.item);
				props.item.modStatus = "NR";
				let userfeed = userFeeds?.filter((x: any) => x.id !== props?.item.id);
				dispatch(setFeeds(userfeed));
				dispatch(setFeedsUpdate(true));
				break;

			// default:
			//     setdatalist(datalist);
		}
		setisconfirmLoading(false);
		props?.setshowconfirmationPopup(false);
	};

	return (
		<Box position="fixed" bgColor={"AVColor.overlay2"} bottom={0} right={0} top={0} left={0} zIndex={9999}>
			<div
				className={`create_post_popup ${mobile && "create_post_popup_mobile"}`}
				style={{
					width: mobile ? dimensions.width - 15 : "600px",
					height: mobile ? "200px" : "200px",
					marginTop: "12px",
					zIndex: 9999,
				}}>
				<HStack justifyContent={"center"} top={"18%"}>
					<Text
						position={"absolute"}
						top={isMobile ? "15px" : "31px"}
						left={isMobile ? "29px" : ""}
						fontSize={[18, 20]}>
						{props?.Confirmationtext}{" "}
					</Text>
				</HStack>
				<HStack justifyContent={"center"} top={"65%"} space={"24px"}>
					{props?.type !== "DeleteAlbum" &&
						props?.type !== "DeleteDepandant" &&
						props?.type !== "DeleteFriends" &&
						props?.type !== "Deleteguardian" &&
						props?.type !== "DeleteParticipant" &&
						props?.type !== "DeleteDocument" &&
						props?.type !== "DeleteStaff" &&
						props?.type !== "DeleteSeason" &&
						props?.type !== "BlockUser" &&
						props?.type !== "RestoreAccount" &&
						props?.type !== "DeleteEvent" &&
						props?.type !== "DeletePlayer" &&
						props?.type !== "RemoveResource" &&
						props?.type !== "DeleteMember" &&
						props?.type !== "DeleteGroup" &&
						props?.type !== "Unfollow" &&
						props?.type !== "DeleteConvo" && (
							<Button
								width={"20%"}
								isLoading={isconfirmLoading}
								height={"40px"}
								borderRadius={"19px"}
								backgroundColor={isconfirmLoading ? "#a9a9a9" : "#e5e5e5"}
								onPress={() => {
									handlefunction(props?.item);
								}}>
								<Text color={"rgba(0,0,0,0.5)"} fontWeight={500} fontSize={[14, 16]}>
									{Loc.currentLang.menu.yes}
								</Text>
							</Button>
						)}
					{(props?.type == "DeleteAlbum" ||
						props?.type == "DeleteDepandant" ||
						props?.type == "DeleteFriends" ||
						props?.type == "Deleteguardian" ||
						props?.type == "DeleteParticipant" ||
						props?.type == "DeleteDocument" ||
						props?.type == "DeleteStaff" ||
						props?.type == "DeleteSeason" ||
						props?.type == "BlockUser" ||
						props?.type == "RestoreAccount" ||
						props?.type == "DeleteEvent" ||
						props?.type == "DeletePlayer" ||
						props?.type == "RemoveResource" ||
						props?.type == "DeleteMember" ||
						props?.type == "DeleteGroup" ||
						props?.type == "DeleteConvo" ||
						props?.type == "Unfollow") && (
						<Button
							width={"20%"}
							isLoading={props?.isLoader}
							height={"40px"}
							borderRadius={"19px"}
							backgroundColor={props?.isLoader ? "#a9a9a9" : "#e5e5e5"}
							onPress={props?.function}>
							<Text color={"rgba(0,0,0,0.5)"} fontWeight={500} fontSize={[14, 16]}>
								{Loc.currentLang.menu.yes}
							</Text>
						</Button>
					)}
					<Button
						width={"22%"}
						height={"40px"}
						onPress={() => {
							props?.setshowconfirmationPopup(false);
						}}
						borderRadius={"19px"}
						backgroundColor={"AVColor.primary"}>
						<Text color={"white"} fontWeight={500} fontSize={[14, 16]}>
							{Loc.currentLang.menu.no}
						</Text>
					</Button>
				</HStack>
			</div>
		</Box>
	);
}
